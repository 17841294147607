<template>
    <section id="education">
        <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="kt-portlet kt-portlet--height-fluid">
                        <!--begin::Widget -->
                        <div class="kt-portlet__body ">
                            <div class="row">
                                <div class="col-sm-2">
                                    <img src="/assets-mp/media/images/EventsShield.png" style="width: 80%;">
                                </div>
                                <div class="col-sm-5">
                                    <h2>U.S. LawShield Events</h2>
                                    <p class="tile-desc">U.S. LawShield hosts member seminars that provide
                                        face-to-face time between experienced lawyers, firearms instructors, and experts
                                        in
                                        numerous
                                        areas of firearms law.</p>
                                    <p class="tile-desc">We host seminars throughout the state, so please come
                                        join your
                                        fellow members. Seminars are also open to non-members on a limited basis.</p>
                                    <a class="btn btn-label-primary btn-lg btn-upper"
                                       href="https://www.gunlawseminar.com"
                                       target="_blank" title="Seminar Schedule" type="button">VIEW
                                        EVENT SCHEDULE</a>
                                </div>
                                <div class="col-sm-5">
                                    <img src="/assets-mp/media/images/education/Seminar.jpg" style="width: 100%">
                                </div>
                            </div>
                        </div>
                        <!--end::Widget -->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="kt-portlet kt-portlet--height-fluid">
                        <div class="kt-portlet__body">
                            <!--begin::Widget -->
                            <span class="tile-desc">See Publications from:</span>
                            <select
                                    :value="region_publication.state"
                                    @change="stateChange($event)"
                                    class="form-control kt-selectpicker"
                                    style="width: 50%"
                            >
                                <option v-bind:data-region="index" v-bind:key="index" v-bind:value="state.name"
                                        v-for="(state, index) in state_list">{{ state.name }}
                                </option>
                            </select>
                            <div class="row mt-3">
                                <div class="col-sm-6" v-show="region_publication.UoF">
                                    <!--Begin::Portlet-->
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__body">
                                            <!--begin::Widget -->
                                            <div class="kt-widget kt-widget--user-profile-2">
                                                <div class="row">
                                                    <div class="col-md-6" style="margin: auto">
                                                        <img :src="'/assets-mp/media/images/education/UseOfForce/' + region_publication.image + 'UseOfForce.png'"
                                                             alt="image"
                                                             class="publication_thumbnail">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <p class="tile-title">WHEN CAN I LEGALLY SHOOT?<br>
                                                            {{region_publication.state.toUpperCase()}} EDITION</p>
                                                        <span class="tile-desc">The Law of deadly force in {{region_publication.state}}</span>
                                                        <div class="kt-widget__footer">
                                                            <a :href="region_publication.UoF_url"
                                                               class="btn btn-label-primary btn-lg btn-upper"
                                                               target="_blank"
                                                               title="Download"
                                                               type="button"><i class="fa fa-download"/> Download
                                                                Publication
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <!--end::Widget -->
                                        </div>
                                    </div>
                                    <!--End::Portlet-->
                                </div>
                                <div class="col-sm-6" v-show="region_publication.CD">
                                    <!--Begin::Portlet-->
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__body">
                                            <!--begin::Widget -->
                                            <div class="kt-widget kt-widget--user-profile-2">
                                                <div class="row">
                                                    <div class="col-md-6" style="margin: auto">
                                                        <img :src="'/assets-mp/media/images/education/CastleDoctrine/' + region_publication.image + 'CastleDoctrine.png'"
                                                             alt="image"
                                                             class="publication_thumbnail">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <p class="tile-title">CASTLE DOCTRINE <br>
                                                            {{region_publication.state.toUpperCase()}} EDITION</p>
                                                        <span class="tile-desc">Your guide to Castle Doctrine</span>
                                                        <div class="kt-widget__footer">
                                                            <a :href="region_publication.CD_url"
                                                               class="btn btn-label-primary btn-lg btn-upper"
                                                               target="_blank"
                                                               title="Download"
                                                               type="button"><i class="fa fa-download"/> Download
                                                                Publication
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end::Widget -->
                                        </div>
                                    </div>
                                    <!--End::Portlet-->
                                </div>
                                <div class="col-md-12"
                                     v-show="!region_publication.UoF && !region_publication.CD">
                                    <!--Begin::Portlet-->
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__body">
                                            <!--begin::Widget -->
                                            <div class="kt-widget kt-widget--user-profile-2">
                                                <div class="row">
                                                    <div class="kt-widget__info">
                                                        <p class="tile-title">There is no info for {{
                                                            region_publication.state }}, check again at a later
                                                            date.</p>
                                                        <span class="tile-desc">Please try selecting another state</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end::Widget -->
                                        </div>
                                    </div>
                                    <!--End::Portlet-->
                                </div>
                            </div>
                            <!--end::Widget -->
                        </div>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <!--Begin::Portlet-->
                    <div class="kt-portlet kt-portlet--height-fluid">
                        <div class="kt-portlet__body">
                            <!--begin::Widget -->
                            <div class="kt-widget kt-widget--user-profile-2">
                                <div class="row">
                                    <div class="col-md-6" style="margin: auto">
                                        <img alt="image"
                                             class="publication_thumbnail"
                                             src="/assets-mp/media/images/education/DefenderReports/ActiveShooter.png">
                                    </div>
                                    <div class="col-md-6">
                                        <p class="tile-title">SURVIVING AN ACTIVE SHOOTER INCIDENT</p>
                                        <p class="tile-desc">Active shooter incidents are on the rise.
                                            Understand the steps you need to take in this special report to maximize
                                            your chances of survival.</p>
                                        <div class="kt-widget__footer">
                                            <a :href="host + active_shooter.url"
                                               class="btn btn-label-primary btn-lg btn-upper"
                                               target="_blank"
                                               title="Download"
                                               type="button"><i class="fa fa-download"/> Download Publication
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!--end::Widget -->
                        </div>
                    </div>
                    <!--End::Portlet-->
                </div>
                <div class="col-sm-6">
                    <!--Begin::Portlet-->
                    <div class="kt-portlet kt-portlet--height-fluid">
                        <div class="kt-portlet__body">
                            <!--begin::Widget -->
                            <div class="kt-widget kt-widget--user-profile-2">
                                <div class="row">
                                    <div class="col-md-6" style="margin: auto">
                                        <img alt="image"
                                             class="publication_thumbnail"
                                             src="/assets-mp/media/images/education/DefenderReports/FiveReasons.png">
                                    </div>
                                    <div class="col-md-6">
                                        <p class="tile-title">I WANT YOU IN PRISON:<br>5 SCARY REASONS
                                            GUN OWNERS SHOULD FEAR THE LEGAL SYSTEM</p>
                                        <p class="tile-desc">If you’re a gun owner, you know the legal system
                                            is not your friend. Simply telling the truth is not enough to keep you
                                            out of jail. Find out why.</p>
                                        <div class="kt-widget__footer">
                                            <a :href="host + five_reasons.url"
                                               class="btn btn-label-primary btn-lg btn-upper"
                                               target="_blank"
                                               title="Download"
                                               type="button"><i class="fa fa-download"/> Download Publication
                                            </a>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <!--end::Widget -->
                        </div>
                    </div>
                    <!--End::Portlet-->
                </div>
                <div class="col-sm-6">
                    <!--Begin::Portlet-->
                    <div class="kt-portlet kt-portlet--height-fluid">
                        <div class="kt-portlet__body">
                            <!--begin::Widget -->
                            <div class="kt-widget kt-widget--user-profile-2">
                                <div class="row">
                                    <div class="col-md-6" style="margin: auto">
                                        <img alt="image"
                                             class="publication_thumbnail"
                                             src="/assets-mp/media/images/education/DefenderReports/PoliceTrafficStops.png">
                                    </div>
                                    <div class="col-md-6">
                                        <p class="tile-title">POTENTIAL TRAFFIC STOPS</p>
                                        <p class="tile-desc">Seeing flashing red, white, and blue lights in your
                                            rear view mirror is rarely a good sign. Learn your rights just in case
                                            you’re ever pulled over.</p>
                                        <div class="kt-widget__footer">
                                            <a :href="host + police_traffic_stop.url"
                                               class="btn btn-label-primary btn-lg btn-upper"
                                               target="_blank"
                                               title="Download"
                                               type="button"><i class="fa fa-download"/> Download Publication
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end::Widget -->
                        </div>
                    </div>
                    <!--End::Portlet-->
                </div>
                <div class="col-sm-6">
                    <!--Begin::Portlet-->
                    <div class="kt-portlet kt-portlet--height-fluid">
                        <div class="kt-portlet__body">
                            <!--begin::Widget -->
                            <div class="kt-widget kt-widget--user-profile-2">
                                <div class="row">
                                    <div class="col-md-6" style="margin: auto">
                                        <img alt="image"
                                             class="publication_thumbnail"
                                             src="/assets-mp/media/images/education/DefenderReports/RoadRage.png">
                                    </div>
                                    <div class="col-md-6">
                                        <p class="tile-title">SURVIVING ROAD RAGE</p>
                                        <p class="tile-desc">Aggressive drivers can be found on most roads. How do you
                                            deal with them safely? Find out in this special report.</p>
                                        <div class="kt-widget__footer">
                                            <a :href="host + road_rage.url"
                                               class="btn btn-label-primary btn-lg btn-upper"
                                               target="_blank"
                                               title="Download"
                                               type="button"><i class="fa fa-download"/> Download Publication
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end::Widget -->
                        </div>
                    </div>
                    <!--End::Portlet-->
                </div>
                <div class="col-sm-6" v-show="region_publication.Trvl">
                    <!--Begin::Portlet-->
                    <div class="kt-portlet kt-portlet--height-fluid">
                        <div class="kt-portlet__body">
                            <!--begin::Widget -->
                            <div class="kt-widget kt-widget--user-profile-2">
                                <div class="row">
                                    <div class="col-md-6" style="margin: auto">
                                        <img alt="image"
                                             class="publication_thumbnail"
                                             src="/assets-mp/media/images/education/DefenderReports/TravelGuide.png">
                                    </div>
                                    <div class="col-md-6">
                                        <p class="tile-title">TRAVEL GUIDE FOR GUN OWNERS<br>
                                          {{region_publication.state.toUpperCase()}} EDITION</p>
                                        <p class="tile-desc">Successfully plan for your next {{region_publication.state}} road trip and avoid common
                                            pitfalls when traveling with your firearms by using the tips in this special
                                            report.</p>
                                        <div class="kt-widget__footer">
                                            <a :href="region_publication.Trvl_url"
                                               class="btn btn-label-primary btn-lg btn-upper"
                                               target="_blank"
                                               title="Download"
                                               type="button"><i class="fa fa-download"/> Download Publication
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end::Widget -->
                        </div>
                    </div>
                    <!--End::Portlet-->
                </div>
                <div class="col-sm-6">
                    <!--Begin::Portlet-->
                    <div class="kt-portlet kt-portlet--height-fluid">
                        <div class="kt-portlet__body">
                            <!--begin::Widget -->
                            <div class="kt-widget kt-widget--user-profile-2">
                                <div class="row">
                                    <div class="col-md-6" style="margin: auto">
                                        <img alt="image"
                                             class="publication_thumbnail"
                                             src="/assets-mp/media/images/education/GunOwnerIDTheftCoverage.png">
                                    </div>
                                    <div class="col-md-6">
                                        <p class="tile-title">YOUR STOLEN GUN NIGHTMARE</p>
                                        <p class="tile-desc">It could never happen to you, right? That’s what most gun
                                            owners think when it comes to their firearms getting stolen. That isn’t
                                            always the case. Read now.</p>
                                        <div class="kt-widget__footer">
                                            <a :href="host + GOID_theft_coverage.url"
                                               class="btn btn-label-primary btn-lg btn-upper"
                                               target="_blank"
                                               title="Download"
                                               type="button"><i class="fa fa-download"/> Download Publication
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end::Widget -->
                        </div>
                    </div>
                    <!--End::Portlet-->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'Education',
        props: {
            member: Object
        },
        mounted() {
            let state = this.member.address.state.split(' ').join('');
            let UseOfForce = this.states[state].UseOfForce;
            let CastleDoctrine = this.states[state].CastleDoctrine;
            let TravelGuide = this.states[state].TravelGuide;
            this.region_publication = {
                state: this.member.address.state,
                image: this.states[state].Image || state,
                UoF: Boolean(UseOfForce),
                UoF_url: UseOfForce,
                CD: Boolean(CastleDoctrine),
                CD_url: this.host + CastleDoctrine,
                Trvl: Boolean(TravelGuide),
                Trvl_url: this.cdn_host + TravelGuide
            };
        },
        data() {
            return {
                host: this.$hostname,
                cdn_host: 'https://cdn.brandfolder.io/UQ735LH6/at/',
                seminar_url: "https://www.gunlawseminar.com/",
                UoF_img: "/assets/img/education/Use%20of%20Force/Texas%20Use%20of%20Force.png",
                CD_img: "/assets/img/education/Castle%20Doctrine/TexasCastleDoctrine.png",
                Trvl_img: '/assets/img/education/Defender%20Reports/Special_Report-TravelGuide.png',
                active_shooter: {
                    image: "/assets/img/education/Defender%20Reports/Special_Report-ActiveShooter.png",
                    url: "/ebooks/Defender%20Reports/Special_Report-ActiveShooter.pdf"
                },
                five_reasons: {
                    image: "/assets/img/education/Defender%20Reports/Special_Report-FiveReasons.png",
                    url: "/ebooks/Defender%20Reports/Special_Report-FiveReasons.pdf"
                },
                police_traffic_stop: {
                    image: "/assets/img/education/Defender%20Reports/Special_Report-PoliceTrafficStops.png",
                    url: "/ebooks/Defender%20Reports/Special_Report-PoliceTrafficStops.pdf"
                },
                road_rage: {
                    image: "/assets/img/education/Defender%20Reports/Special_Report-RoadRage.png",
                    url: "/ebooks/Defender%20Reports/Special_Report-RoadRage.pdf"
                },
                GOID_theft_coverage: {
                    image: "/assets/img/education/GOID/Special_Report-GunOwnerIDTheftCoverage.png",
                    url: "/ebooks/GOID/Special_Report-GunOwnerIDTheftCoverage.pdf"
                },
                states: {
                    Alabama: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/fbh4b5c7mr9b5j3hc2rmmpm/AL_UofF_DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: 'q8psvg97jz8phhhmgkvjkps/AL_Travel_Guide_DIGITAL.pdf'
                    },
                    Alaska: {
                        UseOfForce: null,
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: null
                    },
                    Arizona: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/5rx39w2zn8w5w7w33n2msxsz/AZ-Use_of_Force-DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: '8pbntt27tph98xm596xp5j6p/AZ_Travel_Guide_2021-DIGITAL.pdf'
                    },
                    Arkansas: {
                        UseOfForce: this.$hostname + '/ebooks/Use%20of%20Force/Arkansas%20Use%20of%20Force%20-%20FORTEGRA.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: 'txbkhj86jc4fjkcfk9gqfhgj/AR_Travel_Guide_2021_DIGITAL.pdf'
                    },
                    California: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/2gqc5n7kffqtvvrzcgn7f56/CA_Use_of_Force-DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: 'rpjh3p7zwvm74s4j79mgkj/CA_Travel_Guide_2021-DIGITAL.pdf'
                    },
                    Colorado: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/p9s3twbx5nrqbzcvmvsqrgp3/CO-UoF-22_DIGITAL.pdf',
                        CastleDoctrine: '/ebooks/Castle%20Doctrine/ColoradoCastleDoctrine.pdf',
                        Image: null,
                        TravelGuide: '6jp77v3xqq9r453vm38xn2k/CO_Travel_Guide_2021_DIGITAL.pdf'
                    },
                    Connecticut: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/bnvjxp45w34pkkgh7jttk3k/CT_Use_of_Force_DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: 'tg6bgwf2qwxmrbkgp5rh/CT_Travel_Guide_2021_DIGITAL.pdf'
                    },
                    Delaware: {
                        UseOfForce: null,
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: null
                    },
                    DistrictOfColumbia: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/2xshh9c3r9tc5p8j5gv325b9/DC_Use_of_Force-DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: 'wv84vqq7swrg6kc8xpkp7jf/DC_Travel_Guide_DIGITAL.pdf'
                    },
                    Florida: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/ttx4rrxck75pk4fs6kswqw/FL_Use_of_Force-DIGITAL.pdf',
                        CastleDoctrine: '/ebooks/Castle%20Doctrine/FloridaCastleDoctrine.pdf',
                        Image: null,
                        TravelGuide: 'v7t68sznsj7fsmhsvrx594f/FL_Travel_Guide_2021_DIGITAL.pdf'
                    },
                    Georgia: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/w4b5vfsf8qg9rp54vp8684k/GA_Use_of_Force_DIGITAL.pdf',
                        CastleDoctrine: '/ebooks/Castle%20Doctrine/GeorgiaCastleDoctrine.pdf',
                        Image: null,
                        TravelGuide: 'gc4zqvm9wsnkskjggr3wjwrz/GA_Travel_Guide_DIGITAL.pdf'
                    },
                    Hawaii: {
                        UseOfForce: null,
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: 'x3ccj3599r9nh5fm5z6pcv46/HI_Travel_Guide_DIGITAL.pdf'
                    },
                    Idaho: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/3k25p47wpxjsj8fkwbmvxqx4/ID_Use_of_Force_DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: 'mrwvp78s59kxwbmvmkkcbp9n/ID_Travel_Guide_DIGITAL.pdf'
                    },
                    Illinois: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/28vqnv7sgq655vx9m7bgw/IL_Use_of_Force_DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: '4pxhnn4n2546w6588479qj/IL_Travel_Guide_DIGITAL.pdf'
                    },
                    Indiana: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/f93wq5xc292fhccrm6ffkgk/IN_Use_of_Force-DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: 'vkj9rqqrfg62wgj7tfz9krj/IN_Travel_Guide_DIGITAL.pdf'
                    },
                    Iowa: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/2qfphcsq5t78t3gh5nrhz9h/IA-UoF_DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: 'qk4pk8ggmb7jqt8v85242k86/IA_Travel_Guide_DIGITAL.pdf'
                    },
                    Kansas: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/qx5j3cmncjks4xh7gb5p4xgs/Kansas_UofF_DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: 'f79ghj3wfv7wgmm8hrb3h5hv/KS_Travel_Guide_DIGITAL.pdf'
                    },
                    Kentucky: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/tznsqcq5w9kbkrjbzjxrbxk/KY_Use_of_Force_DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: '9trg447sw6m49h34hw6c4g/KY_Travel_Guide_2021_DIGITAL.pdf'
                    },
                    Louisiana: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/pgvhg3jr6jnwxw79x6m35v/LA_Use_of_Force_DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: 'vpfzcqj37ntt8rcsw8v6qt/LA_Travel_Guide_DIGITAL.pdf'
                    },
                    Maine: {
                        UseOfForce: null,
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: null
                    },
                    Maryland: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/8svmv86ccfp5gvx2xs5/MD_Use_of_Force-DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: 'q7653q39w5nxvz4fn9skt7/MD_Travel_Guide_DIGITAL.pdf'
                    },
                    Massachusetts: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/cwsn4smmsr75r9jrvwhsjfn/MA_Use_of_Force_DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: '8vt3w9sgnwrtc7w9jtm858w/MA_Travel_Guide_DIGITAL.pdf'
                    },
                    Michigan: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/gh3fwjq9f6km74gnxmzcjcq/MI_Use_of_Force-DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: 'phqqw7m46fgmpj6kv5hm9k4b/MI_Travel_Guide_DIGITAL.pdf'
                    },
                    Minnesota: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/k64f5s2n7rg5997knp78cf/MN_Use_of_Force-DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: 'npj8wtk3ws8sn4mck77k6hfw/MN_Travel_Guide_DIGITAL.pdf'
                    },
                    Mississippi: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/wwjn3bkbx3g8vrfscwvn5mqq/MS_Use_of_Force_DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: '7jm996wtn9t86hfxqq3k6gm/MS_Travel_Guide_DIGITAL.pdf'
                    },
                    Missouri: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/w5tpkjcx4gprk3xxvzfk/MO_Use_of_Force_DIGITAL.pdf',
                        CastleDoctrine: '/ebooks/Castle%20Doctrine/MissouriCastleDoctrine.pdf',
                        Image: null,
                        TravelGuide: 'pzhz2ghj4jrzrz8wbsq95znv/MO_Travel_Guide_DIGITAL.pdf'
                    },
                    Montana: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/v8xvgrs74m84rtq7nqxxqmc/MT_Use_of_Force-DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: 'cfck3mwhcn88r6g3hhp9557/MT_Travel_Guide_DIGITAL.pdf'
                    },
                    Nebraska: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/5z6hxxn8s8zg84btnsxt2s/NE_Use_of_Force_DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: 'bsh8434s3hvtpvw8wg5xms4/NE_Travel_Guide_DIGITAL.pdf'
                    },
                    Nevada: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/fwpg6hvnn7gfmppxj8cq4p6/NV_Use_of_Force_DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: '48c5h42xgz87g6xmvfsksms7/NV_Travel_Guide_DIGITAL.pdf'
                    },
                    NewHampshire: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/xsn99mvs9jfj99rmbbwqn79w/NH_Use_of_Force_DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: '5sp73s9fsrxkgh8586twn/NH_Travel_Guide_DIGITAL.pdf'
                    },
                    NewJersey: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/jbqr8jcnq3p9h5rphc6b24fr/NJ_Use_of_Force_DIGITAL.pdf',
                        CastleDoctrine: '/ebooks/Castle%20Doctrine/NewJerseyCastleDoctrine.pdf',
                        Image: null,
                        TravelGuide: '4f3vbqh3sjs7kr2w9ng93tp5/NJ-Travel-Guide-DIGITAL.pdf'
                    },
                    NewMexico: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/4sgpjbn35m5zhtj7n97nx9n8/NM_Use_of_Force_DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: 'jk7rshckxfck4hn7rkwcc3/NM_Travel_Guide_2022-DIGITAL.pdf'
                    },
                    NewYork: {
                        UseOfForce: null,
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: null
                    },
                    NorthCarolina: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/nnn4cpm5mx4c4gtgpr2xh5j/NC_Use_of_Force-DIGITAL.pdf',
                        CastleDoctrine: '/ebooks/Castle%20Doctrine/NorthCarolinaCastleDoctrine.pdf',
                        Image: null,
                        TravelGuide: '3ttkx2f29xm3tgmm9s3cz8k/NC_Travel_Guide_DIGITAL.pdf'
                    },
                    NorthDakota: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/5q4rz9vfmmcp93xfmxmxwv/ND_Use_of_Force_DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: '4fbrpr4575xpj6fqqgzvp/ND_Travel_Guide_2021_DIGITAL.pdf'
                    },
                    Ohio: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/rffxmqkm7gkrqcn9grmg4fqt/OH_Use_of_Force-DIGITAL.pdf',
                        CastleDoctrine: '/ebooks/Castle%20Doctrine/OhioCastleDoctrine.pdf',
                        Image: null,
                        TravelGuide: 'gr87gm2jtf43mg8frskkrgxh/OH_Travel_Guide_DIGITAL.pdf'
                    },
                    Oklahoma: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/pxstnc87pp5gcgvksvp386/OK_UofF_DIGITAL.pdf',
                        CastleDoctrine: '/ebooks/Castle%20Doctrine/OklahomaCastleDoctrine.pdf',
                        Image: null,
                        TravelGuide: 'mhh5w6xq2fkqvgv6pxzhrjj/OK_Travel_Guide_DIGITAL.pdf'
                    },
                    Oregon: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/wmmnqffw8bww8f8vbstkmqps/OR_Use_of_Force_2022_DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: 'qjbqt6qshk3f8g7jxv3b963/OR_Travel_Guide_DIGITAL.pdf'
                    },
                    Pennsylvania: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/pjpq868p6fbzxp5swnpzbrj5/PA_Use_of_Force_DIGITAL.pdf',
                        CastleDoctrine: '/ebooks/Castle%20Doctrine/PennsylvaniaCastleDoctrine.pdf',
                        Image: null,
                        TravelGuide: 'fhttwmbk38g97xqmcxsst9hr/PA_Travel_Guide_DIGITAL.pdf'
                    },
                    PuertoRico: {
                        UseOfForce: null,
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: null
                    },
                    RhodeIsland: {
                        UseOfForce: null,
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: null
                    },
                    SouthCarolina: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/582b2zt3w93xnfj5r96vcnrg/SC_Use_of_Force_2022-DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: 'sx89g8hxh3xkv78c88h/SC_Travel_Guide_DIGITAL.pdf'
                    },
                    SouthDakota: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/mg4tj3578tst4g5m8qcs8pm/SD_Use_of_Force_DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: 'cngff9pkkvx9fqrt5sfk6/SD_Travel_Guide_DIGITAL.pdf'
                    },
                    Tennessee: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/8n5966b8v6ncm7hwb8np8n/TN_Use_of_Force_DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: '8w8xnqtgnkg39gpt5bj2zx9/TN_Travel_Guide_DIGITAL.pdf'
                    },
                    Texas: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/88jpn3fbwbswg3fjb3f4ncc/TX_Use_of_Force-DIGITAL.pdf',
                        CastleDoctrine: '/ebooks/Castle%20Doctrine/TexasCastleDoctrine.pdf',
                        Image: null,
                        TravelGuide: 'bgckhsjx63k3jcpmbrw984qv/TX_Travel_Guide_DIGITAL.pdf'
                    },
                    Utah: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/m8tmnk55h6rk7kj6g7g3qsm3/UT_Use_of_Force-DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: 'vmnmf8chfjqsxwm5m8256kz/UT_Travel_Guide_DIGITAL.pdf'
                    },
                    Vermont: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/3xp4m7m5bz8z23b9tshb8ss/VT_Use_of_Force_DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: '7xvpqskjcvjs3x23k9tt36nw/VT_Travel_Guide_DIGITAL.pdf'
                    },
                    Virginia: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/39skrqxv8fph78qp7r8cp8/VA_Use_of_Force_DIGITAL.pdf',
                        CastleDoctrine: '/ebooks/Castle%20Doctrine/VirginaCastleDoctrine.pdf',
                        Image: null,
                        TravelGuide: 'bknnw9r87mmwtsk3vrh7t2f/VA_Travel_Guide_2021_DIGITAL.pdf'
                    },
                    Washington: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/r357q3ptpgjj4g3w2tcnqkq/WA_Use_of_Force_DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: '8hqw7z7rb69npbfsf9mzqtq6/WA_Travel_Guide_Digital.pdf'
                    },
                    WashingtonDC: {
                        UseOfForce: this.$hostname + '/ebooks/Use%20of%20Force/DC%20Use%20of%20Force.pdf',
                        CastleDoctrine: null,
                        Image: 'DistrictOfColumbia',
                        TravelGuide: 'wv84vqq7swrg6kc8xpkp7jf/DC_Travel_Guide_DIGITAL.pdf'
                    },
                    WestVirginia: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/ccmf7t56gxf9vz25546jt/WV_Use_of_Force-DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: '8s3sqrvqswp44nmmhbm3v9n/WV_Travel_Guide_DIGITAL.pdf'
                    },
                    Wisconsin: {
                        UseOfForce: 'https://cdn.brandfolder.io/UQ735LH6/at/9hptmqcvpjbvvjfkb5sx66w/WI_Use_of_Force_2022-DIGITAL.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: 'n6gmbfp92h55hbgnks7hn9mz/WI_Travel_Guide_DIGITAL.pdf'
                    },
                    Wyoming: {
                        UseOfForce: this.$hostname + '/ebooks/Use%20of%20Force/Wyoming%20Use%20of%20Force.pdf',
                        CastleDoctrine: null,
                        Image: null,
                        TravelGuide: null
                    }
                },
                state_list:
                    [
                        {
                            "name": "Alabama",
                            "abbreviation": "AL"
                        },
                        {
                            "name": "Alaska",
                            "abbreviation": "AK"
                        },
                        {
                            "name": "Arizona",
                            "abbreviation": "AZ"
                        },
                        {
                            "name": "Arkansas",
                            "abbreviation": "AR"
                        },
                        {
                            "name": "California",
                            "abbreviation": "CA"
                        },
                        {
                            "name": "Colorado",
                            "abbreviation": "CO"
                        },
                        {
                            "name": "Connecticut",
                            "abbreviation": "CT"
                        },
                        {
                            "name": "Delaware",
                            "abbreviation": "DE"
                        },
                        {
                            "name": "District Of Columbia",
                            "abbreviation": "DC"
                        },
                        {
                            "name": "Florida",
                            "abbreviation": "FL"
                        },
                        {
                            "name": "Georgia",
                            "abbreviation": "GA"
                        },
                        {
                            "name": "Hawaii",
                            "abbreviation": "HI"
                        },
                        {
                            "name": "Idaho",
                            "abbreviation": "ID"
                        },
                        {
                            "name": "Illinois",
                            "abbreviation": "IL"
                        },
                        {
                            "name": "Indiana",
                            "abbreviation": "IN"
                        },
                        {
                            "name": "Iowa",
                            "abbreviation": "IA"
                        },
                        {
                            "name": "Kansas",
                            "abbreviation": "KS"
                        },
                        {
                            "name": "Kentucky",
                            "abbreviation": "KY"
                        },
                        {
                            "name": "Louisiana",
                            "abbreviation": "LA"
                        },
                        {
                            "name": "Maine",
                            "abbreviation": "ME"
                        },
                        {
                            "name": "Maryland",
                            "abbreviation": "MD"
                        },
                        {
                            "name": "Massachusetts",
                            "abbreviation": "MA"
                        },
                        {
                            "name": "Michigan",
                            "abbreviation": "MI"
                        },
                        {
                            "name": "Minnesota",
                            "abbreviation": "MN"
                        },
                        {
                            "name": "Mississippi",
                            "abbreviation": "MS"
                        },
                        {
                            "name": "Missouri",
                            "abbreviation": "MO"
                        },
                        {
                            "name": "Montana",
                            "abbreviation": "MT"
                        },
                        {
                            "name": "Nebraska",
                            "abbreviation": "NE"
                        },
                        {
                            "name": "Nevada",
                            "abbreviation": "NV"
                        },
                        {
                            "name": "New Hampshire",
                            "abbreviation": "NH"
                        },
                        {
                            "name": "New Jersey",
                            "abbreviation": "NJ"
                        },
                        {
                            "name": "New Mexico",
                            "abbreviation": "NM"
                        },
                        {
                            "name": "New York",
                            "abbreviation": "NY"
                        },
                        {
                            "name": "North Carolina",
                            "abbreviation": "NC"
                        },
                        {
                            "name": "North Dakota",
                            "abbreviation": "ND"
                        },
                        {
                            "name": "Ohio",
                            "abbreviation": "OH"
                        },
                        {
                            "name": "Oklahoma",
                            "abbreviation": "OK"
                        },
                        {
                            "name": "Oregon",
                            "abbreviation": "OR"
                        },
                        {
                            "name": "Pennsylvania",
                            "abbreviation": "PA"
                        },
                        {
                            "name": "Puerto Rico",
                            "abbreviation": "PR"
                        },
                        {
                            "name": "Rhode Island",
                            "abbreviation": "RI"
                        },
                        {
                            "name": "South Carolina",
                            "abbreviation": "SC"
                        },
                        {
                            "name": "South Dakota",
                            "abbreviation": "SD"
                        },
                        {
                            "name": "Tennessee",
                            "abbreviation": "TN"
                        },
                        {
                            "name": "Texas",
                            "abbreviation": "TX"
                        },
                        {
                            "name": "Utah",
                            "abbreviation": "UT"
                        },
                        {
                            "name": "Vermont",
                            "abbreviation": "VT"
                        },
                        {
                            "name": "Virginia",
                            "abbreviation": "VA"
                        },
                        {
                            "name": "Washington",
                            "abbreviation": "WA"
                        },
                        {
                            "name": "Washington DC",
                            "abbreviation": "DC"
                        },
                        {
                            "name": "West Virginia",
                            "abbreviation": "WV"
                        },
                        {
                            "name": "Wisconsin",
                            "abbreviation": "WI"
                        },
                        {
                            "name": "Wyoming",
                            "abbreviation": "WY"
                        }
                    ],
                region_publication: {
                    state: null,
                    image: null,
                    UoF: null,
                    UoF_url: null,
                    CD: null,
                    CD_url: null,
                    Trvl: null,
                    Trvl_url: null
                },
            }
        },
        methods: {
            stateChange(event) {
                let state = event.target.value;
                let splitState = state.split(' ').join('');
                let UoF = this.states[splitState].UseOfForce;
                let CD = this.states[splitState].CastleDoctrine;
                let Trvl = this.states[splitState].TravelGuide;
                this.region_publication.state = state;
                this.region_publication.image = this.states[splitState].Image || splitState;
                this.region_publication.UoF = Boolean(UoF);
                this.region_publication.UoF_url = UoF;
                this.region_publication.CD = Boolean(CD);
                this.region_publication.CD_url = this.host + CD;
                this.region_publication.Trvl = Boolean(Trvl);
                this.region_publication.Trvl_url = this.cdn_host + Trvl;
            }
        }
    }
</script>

<style>

    .publication_thumbnail {
        height: auto;
        width: 100%;
    }

    .tile-desc {
        display: block;
        font-weight: 500;
        font-size: 1.1rem;
        padding-top: 0.4rem;
        color: #74788d;
    }

    .tile-title {
        font-size: 1.4rem;
        color: #48465b;
        font-weight: 500;
    }

</style>
