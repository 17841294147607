<template>
    <div aria-hidden="true" aria-labelledby="exampleModalLabel" id="account-balance-modal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Welcome {{ member.firstName }}. You have an
                        outstanding balance.</h5>
                    <button aria-label="Close" class="close" type="button">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="kt-portlet__body">
                        <div class="kt-section">
                            <div class="kt-section__content">
                                <div style="background-color: #f7f8fa; padding: 20px">
                                    <h3>Your account balance: <span style="float: right!important;">${{ member.balance.toFixed(2) }}</span>
                                    </h3>
                                </div>
                                <div style="padding: 20px">
                                    <p> To pay this balance, click <b>Pay Balance</b> to be redirected.</p>
                                    <p> Click <b>Continue</b> to close this pop up.</p>
                                    <p><b>Your Coverage may be in jeopardy if you have an outstanding balance.</b></p>
                                </div>
                                <div>
                                    <button
                                            @click="close('/billing/make-payment')"
                                            class="btn btn-label-success btn-bold btn-upper"
                                            style="margin-left: 20px"
                                    >
                                        Pay Balance
                                    </button>
                                    <button
                                            @click="close(null)"
                                            class="btn btn-label-primary btn-bold btn-upper"
                                            style="margin-left: 20px"
                                    >
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "ActiveAccountBalance",
        props: {
            member: Object
        },
        methods: {
            close: function (route) {
                this.$emit('close');
                if (route)
                    this.$router.push(route);
            }
        }
    }
</script>

<style scoped>

</style>