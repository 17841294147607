<template>
    <div aria-hidden="true" aria-labelledby="exampleModalLabel" id="password-reset-modal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Welcome {{ member.firstName }}. Please change your password.</h5>
                    <button aria-label="Close" class="close" type="button">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="kt-portlet__body">
                        <div class="kt-section">
                            <div class="kt-section__content">
                                <ChangePassword :member="member" @password-change-successful="close" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import ChangePassword from "../member/profile/ChangePassword";

    export default {
        name: "CheckAccountStatus",
        components: {ChangePassword},
        props:{
            member: Object
        },
        methods: {
            close: function () {
                setTimeout(()=>{
                    this.$emit('close');
                    this.$parent.$emit('password-changed');
                },1500);
            }
        }
    }
</script>

<style scoped>

</style>