"use strict";

export class MemberRepo {

    constructor(axios, zuora_id) {
        this.axios = axios;
        this.zuora_id = zuora_id;
        this.member = null;
        this.dbMember = null;
    }

    get() {
        return this.member;
    }

    getDBMember() {
        return this.member;
    }

    fetchInfo() {
        return new Promise((resolve, reject) => {
            this.axios.get('/zuora/members/' + this.zuora_id)
                .then(response => {
                    const is_secondary = localStorage.getItem('member:member_number') !== response.data.basicInfo.MemberNumber__c;
                    localStorage.setItem('primary', String(!is_secondary));
                    const name = response.data.basicInfo.name;

                    const makeMember = function (response) {
                        return {
                            zuora_id: response.data.basicInfo.id,
                            account_type: response.data.basicInfo.AccountType__c,
                            username:
                                is_secondary ? response.data.basicInfo.SecondaryMemberNumber__c : response.data.basicInfo.MemberNumber__c,
                            member_number:
                                is_secondary ? response.data.basicInfo.SecondaryMemberNumber__c : response.data.basicInfo.MemberNumber__c,
                            status: response.data.basicInfo.status,
                            prefix:
                                is_secondary ? response.data.basicInfo.SecondaryNamePrefix__c : response.data.basicInfo.NamePrefix__c,
                            firstName:
                                is_secondary ? response.data.basicInfo.SecondaryFirstName__c : name.split(' ')[0],
                            lastName:
                                is_secondary ? response.data.basicInfo.SecondaryLastName__c : name.split(' ').splice(1).join(' '),
                            suffix:
                                is_secondary ? response.data.basicInfo.SecondaryNameSuffix__c : response.data.basicInfo.NameSuffix__c,
                            communicationProfileId: response.data.basicInfo.communicationProfileId,
                            is_secondary: is_secondary,
                            has_secondary: Boolean(response.data.basicInfo.SecondaryMemberNumber__c),
                            region: response.data.basicInfo.Region__c,
                            email: {
                                work:
                                    is_secondary ? response.data.basicInfo.SecondaryEmailAddress__c : response.data.soldToContact.workEmail,
                                home:
                                    is_secondary ? response.data.basicInfo.SecondaryEmailAddress__c : response.data.soldToContact.personalEmail
                            },
                            phone: {
                                home: response.data.soldToContact.homePhone,
                                cell: response.data.soldToContact.mobilePhone,
                                work: response.data.soldToContact.workPhone,
                            },
                            address: {
                                address1: response.data.soldToContact.address1 +  ' ' + (response.data.soldToContact.address2 || ''),
                                address2: response.data.soldToContact.address2 || '',
                                city: response.data.soldToContact.city,
                                state: response.data.soldToContact.state,
                                zipcode: response.data.soldToContact.zipCode,
                                country: response.data.soldToContact.country
                            },
                            balance: response.data.metrics.balance,
                        };
                    };

                    this.member = makeMember(response);

                    resolve(this.member);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    fetchBasicInfo(id = this.zuora_id) {
        return new Promise((resolve, reject) => {
            this.axios.get('/usls/members/' + id)
                .then(response => {
                    //const name = response.data.basicInfo.name;
                    const makeMember = function (response) {
                        return {
                            zuora_id: response.data.zuora_id,
                            username: response.data.username,
                            member_number: response.data.member_number,
                            status: response.data.status,
                            prefix: response.data.prefix,
                            firstName: response.data.first_name,
                            lastName: response.data.last_name,
                            suffix: response.data.suffix,
                            portals: response.data.portals,
                            is_active: response.data.is_active,
                            first_login: response.data.first_login,
                            region_id: response.data.member_address.region_id,
                        };
                    };

                    this.dbMember = makeMember(response);
                    resolve(this.dbMember);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
}
