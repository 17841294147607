"use strict";

export class InvoiceRepo {

    constructor(axios) {
        this.axios = axios;
        this.invoices = {};
    }

    get(invoiceId) {
        return this.invoices[invoiceId];
    }

    all() {
        return this.invoices;
    }

    fetch(invoiceId) {
        return new Promise((resolve, reject) => {
        this.axios.get('/zuora/invoices/' + invoiceId)
            .then(response => {
                this.invoices[invoiceId] = response.data;
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
        });
    }

    fetchSummary(zuoraId) {
        return new Promise((resolve, reject) => {
        this.axios.get('/zuora/members/' + zuoraId + '/invoices')
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
        });
    }
}
