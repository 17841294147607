<template>
    <div aria-hidden="true" aria-labelledby="exampleModalLabel" id="account-balance-modal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title">Terms and Conditions</h1>
                </div>
                <div class="modal-body">
                    <div class="kt-portlet__body">
                        <div class="kt-section">
                            <div class="kt-section__content">
                                <h2>Acceptance of Terms and Conditions</h2>
                                <div style="background-color: #f7f8fa; padding: 10px">
                                    <p>
                                        The following Terms and Conditions, which include our Privacy Policy, apply when
                                        you view or use the website TexasLawShield.com, USLawShield.com and all websites
                                        associated with the TexasLawShield.com and USLawShield.com domain (our "Site"),
                                        whether as a guest or as a registered user. These Terms and Conditions also
                                        govern the supply of any of the products listed on our Site to you, including
                                        all features and functionalities, educational resources, our website and user
                                        interfaces, and all content associated there with (the "Products and Services").
                                        By using, visiting, or browsing the Site, or by ordering any of our Products and
                                        Services, including but not limited to U.S. & Texas LawShield© memberships, you
                                        accept and agree to be bound b these Terms and Conditions. In addition, checking
                                        a box and clicking any button to submit information to the company are the
                                        equivalent of your electronic signature.
                                    </p>
                                </div>
                                <div style="margin-top: 20px">
                                    <button
                                            @click="close()"
                                            class="btn btn-label-primary btn-bold btn-upper"
                                            style="margin-left: 20px"
                                    >
                                        Close
                                    </button>
                                    <a
                                            @click="close()"
                                            class="btn btn-label-primary btn-bold btn-upper"
                                            style="margin-left: 20px"
                                            target="_blank"
                                            :href=" this.$hostname +'/terms-and-conditions/'"
                                    >
                                        Full Terms and Conditions
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "TermsAndConditions",
        methods: {
            close: function () {
                this.$emit('close');
            }
        }
    }
</script>

<style scoped>

</style>