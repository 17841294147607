<template>
    <section id="billing_account">
        <div class="row">
            <div class="col-xl-12">
                <div class="kt-portlet kt-portlet--height-fluid">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">Billing Information<small>change or reset your account
                                password</small></h3>
                        </div>
                    </div>
                    <div class="kt-portlet__body">
                        <div class="kt-section kt-section--first">
                            <div class="kt-section__body">
<!--                                <p>To update an expiration date or your security code for an existing card, please-->
<!--                                    use the "Edit This Card" feature.</p>-->
                                <p>To add a new card to your account, please use the "Add New Card" feature. If you
                                    wish to make this your default card, please check "Set as Default".</p>
                                <p>After updating or adding your new card, please <b>"Refresh the Page"</b>.</p>
                                <p>If you have any question or to process a payment, please contact our member
                                    service team at (877) 448-6839.</p>
                                <div class="row">
                                    <div class="col-md-12">
                                        <alert v-on:close-alert="showAlert = false" :message="message" :show="showAlert" :type="alertType"/>
                                        <div class="table-responsive">
                                            <table class="table table-striped"
                                                   v-on:beganAddingCard="onAddingCard"
                                                   v-on:cardAdded="onCardAdded"
                                            >
                                                <thead>
                                                <tr>
                                                    <th colspan="4">Credit Cards</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="card in creditCards" :key="card.id">
                                                    <td class="kt-font-brand kt-font-bold">
                                                        {{card.cardType}} Ending in: {{card.cardNumber.slice(-4)}}
                                                    </td>
                                                    <td class="kt-font-brand kt-font-bold">
                                                        Expiration: {{card.expirationMonth + '/' + card.expirationYear}}
                                                    </td>
                                                    <td>
                                                        <button :disabled="card.defaultPaymentMethod"
                                                                @click="setDefaultCard(card.id)"
                                                                class="btn btn-bold btn-label-brand btn-sm">
                                                            <span v-if="card.defaultPaymentMethod">Default</span>
                                                            <span v-if="!card.defaultPaymentMethod">Set As Default</span>
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <button v-if="!card.defaultPaymentMethod"
                                                                @click="deleteCard(card.id)"
                                                                class="btn btn-bold btn-label-brand btn-sm">
                                                            Delete This Card
                                                        </button>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="kt-widget11__action kt-align-right">
                                            <button @click="addCard"
                                                    class="btn btn-label-brand btn-bold btn-sm btn-wide" type="button">
                                                Add A New Card
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="kt-portlet__foot">
                        <div class="kt-form__actions">
                            <div class="row">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
    import CCModal from "./CCModal";
    import {CreditCardRepo} from '@/repositories/CreditCardRepo';

    export default {
        props: {
            member: Object,
        },
        data() {
            return {
                creditCards: [],
                beganAddingCard: false,
                showAlert: false,
                alertType: null,
                message: ''
            }
        },
        beforeRouteEnter(to, from , next){
            let member = JSON.parse(localStorage.getItem('member'));
            if (member.is_secondary) {
                next('/');
            } else {
                next();
            }
        },
        mounted() {
            this.fetchCreditCards();
        },
        watch: {
            member: function () {
                this.fetchCreditCards();
            }
        },
        methods: {
            onAddingCard: function () {
                this.beganAddingCard = true;
            },
            onCardAdded: function (creditCardInfo) {
                this.beganAddingCard = false;

                const creditCard = {
                    id: creditCardInfo.id,
                    cardHolderInfo: {
                        cardHolderName: creditCardInfo.cardHolderName,
                        addressLine1: null,
                        addressLine2: null,
                        city: null,
                        state: null,
                        zipcode: null
                    },
                    cardNumber: creditCardInfo.creditCardNumber,
                    cardType: creditCardInfo.creditCardType,
                    defaultPaymentMethod: creditCardInfo.defaultPaymentMethod,
                    expirationMonth: creditCardInfo.expirationMonth,
                    expirationYear: creditCardInfo.expirationYear
                };

                if(creditCard.defaultPaymentMethod) {
                    this.creditCards.find(function (card) {
                        return card.defaultPaymentMethod === true;
                    }).defaultPaymentMethod = false;
                }

                this.showAlert = true;
                this.message = "Credit card was successfully added.";
                this.creditCards.unshift(creditCard);
            },
            fetchCreditCards: function () {
                const ccRepo = new CreditCardRepo(this.axios, this.member.zuora_id);
                ccRepo.fetchInfo()
                    .then(creditCards => {
                        this.creditCards = creditCards.filter(function (card) {
                            return card.cardNumber !== null;
                        });
                    })
                    .catch(error => {
                        console.warn(error);
                        this.error = error;
                        this.failure = true;
                        this.alertType = 'danger';
                        this.message = "Something went wrong. Please try again later.";
                    })
                    .finally(() => this.loading = false);
            },
            addCard: function () {
                let _this = this;
                this.$modal.show(
                    CCModal,
                    {
                        member: this.member
                    },
                    {
                        name: 'add-card',
                        adaptive: true,
                        height: 'auto',
                        width: '60%',
                        minWidth: '320',
                        scrollable: true,
                        beganAddingCard: false
                    },
                    {
                        'before-close': _this.beforeClose,
                        'beganAddingCard': function (event) {
                            _this.onAddingCard(event);
                        },
                        'cardAdded': function (creditCard) {
                            _this.onCardAdded(creditCard);
                        }
                    }
                );
            },
            beforeClose(event) {
                if (this.beganAddingCard === true) {
                    if (confirm('Do you really want to close this form?') === false) {
                        event.stop();
                    }
                }
            },
            deleteCard: function (id) {
                this.axios.delete("/zuora/members/" + this.member.zuora_id + "/creditCards/" + id)
                    .then(() => {
                        this.creditCards.splice(id, 1);
                        this.showAlert = true;
                        this.alertType = 'success';
                        this.message = "Credit card was successfully deleted.";
                        this.fetchCreditCards();
                    })
                    .catch(error => {
                        console.warn(error);
                        this.showAlert = true;
                        this.alertType = 'danger';
                        this.message = "Something went wrong. Please try again later.";
                    });
            },
            setDefaultCard: function (id) {
                this.axios.put("/zuora/members/" + this.member.zuora_id + "/creditCards/" + id, {
                    defaultPaymentMethod: true
                })
                    .then(response => {
                        if(response.data.success) {
                            let defaultCard = this.creditCards.find(function (card) {
                                return card.defaultPaymentMethod === true;
                            });
                            let newDefaultCard = this.creditCards.find(function (card) {
                                return card.id === response.data.paymentMethodId;
                            });
                            defaultCard.defaultPaymentMethod = false;
                            newDefaultCard.defaultPaymentMethod = true;
                            this.showAlert = true;
                            this.alertType = 'success';
                            this.message = "Credit card was successfully set to default.";
                            this.fetchCreditCards();
                        } else {
                        console.warn(response);
                        this.showAlert = true;
                        this.alertType = 'danger';
                        this.message = "Something went wrong. Please try again.";
                        }
                    })
                    .catch(error => {
                        console.warn(error);
                        this.showAlert = true;
                        this.alertType = 'danger';
                        this.message = "Something went wrong. Please try again later.";
                    });
            }
        }
    }
</script>

<style scoped>

</style>
