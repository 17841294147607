<template>
    <section id="personal_info">
        <div class="row">
            <div class="col-xl-12">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">Personal Information <small>update your
                                personal information</small></h3>
                        </div>
                    </div>
                    <div class="content">
                        <form @submit.prevent="editProfile" class="kt-form kt-form--label-right">
                            <div class="kt-portlet__body">
                                <div class="kt-section kt-section--first">
                                    <div class="kt-section__body">
                                        <div class="row">
                                            <label class="col-xl-3"></label>
                                            <div class="col-lg-9 col-xl-6">
                                                <h3 class="kt-section__title kt-section__title-sm">Customer
                                                    Info:</h3>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-xl-3 col-lg-3 col-form-label" for="prefix">Prefix</label>
                                            <div class="col-lg-9 col-xl-6">
                                                <select
                                                        class="form-control kt-selectpicker"
                                                        data-width="100px"
                                                        id="prefix"
                                                        v-model="member.prefix"
                                                >
                                                    <option></option>
                                                    <option>Dr</option>
                                                    <option>Mr</option>
                                                    <option>Mrs</option>
                                                    <option>Ms</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-xl-3 col-lg-3 col-form-label" for="first_name">First
                                                Name</label>
                                            <div class="col-lg-9 col-xl-6">
                                                <input class="form-control" id="first_name" type="text"
                                                       v-model="member.firstName"/>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-xl-3 col-lg-3 col-form-label" for="last_name">Last
                                                Name</label>
                                            <div class="col-lg-9 col-xl-6">
                                                <input class="form-control" id="last_name" type="text"
                                                       v-model="member.lastName"/>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-xl-3 col-lg-3 col-form-label" for="suffix">Suffix</label>
                                            <div class="col-lg-9 col-xl-6">
                                                <select
                                                        class="form-control kt-selectpicker"
                                                        data-width="100px"
                                                        id="suffix"
                                                        v-model="member.suffix"
                                                >
                                                    <option></option>
                                                    <option>II</option>
                                                    <option>III</option>
                                                    <option>IV</option>
                                                    <option>Jr</option>
                                                    <option>Sr</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-xl-3 col-lg-3 col-form-label" for="home_phone">Home
                                                Phone</label>
                                            <div class="col-lg-9 col-xl-6">
                                                <div class="input-group">
                                                    <div class="input-group-prepend"><span
                                                            class="input-group-text"><i
                                                            class="la la-phone"></i></span></div>
                                                    <vue-phone-number-input
                                                            class="phone-number-input"
                                                            default-country-code="US"
                                                            id="home_phone"
                                                            no-country-selector="true"
                                                            no-example="true"
                                                            size="lg"
                                                            v-model="member.phone.home"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row" v-if="!this.member.is_secondary">
                                            <label class="col-xl-3 col-lg-3 col-form-label" for="cell_phone">Cell
                                                Phone</label>
                                            <div class="col-lg-9 col-xl-6">
                                                <div class="input-group">
                                                    <div class="input-group-prepend"><span
                                                            class="input-group-text"><i
                                                            class="la la-phone"></i></span></div>
                                                    <vue-phone-number-input
                                                            class="phone-number-input"
                                                            default-country-code="US"
                                                            id="cell_phone"
                                                            no-country-selector="true"
                                                            no-example="true"
                                                            size="lg"
                                                            v-model="member.phone.cell"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-xl-3 col-lg-3 col-form-label" for="work_phone">Work
                                                Phone</label>
                                            <div class="col-lg-9 col-xl-6">
                                                <div class="input-group">
                                                    <div class="input-group-prepend"><span
                                                            class="input-group-text"><i
                                                            class="la la-phone"></i></span></div>
                                                    <vue-phone-number-input
                                                            :no-country-selector="true"
                                                            :no-example="true"
                                                            class="phone-number-input"
                                                            default-country-code="US"
                                                            id="work_phone"
                                                            size="lg"
                                                            v-model="member.phone.work"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-xl-3 col-lg-3 col-form-label" for="email">Email
                                                Address</label>
                                            <div class="col-lg-9 col-xl-6">
                                                <div class="input-group">
                                                    <div class="input-group-prepend"><span
                                                            class="input-group-text"><i
                                                            class="la la-at"></i></span></div>
                                                    <input class="form-control" id="email" placeholder="Email"
                                                           type="email" v-model="email"/>
                                                </div>
                                                <span class="form-text text-muted">We do not share your email with anyone.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="kt-portlet__foot">
                                <div class="kt-form__actions">
                                    <div class="row">
                                        <div class="col-lg-3 col-xl-3">
                                        </div>
                                        <div class="col-lg-9 col-xl-9">
                                            <alert :class="'col-lg-6'" :message="message"
                                                   :show="showAlert" :type="alertType"
                                                   v-on:close-alert="showAlert = false"/>
                                            <button class="btn btn-brand btn-bold" id="submit_personal_info">Submit</button>&nbsp;
                                            <button class="btn btn-secondary" type="reset">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        props: {
            member: Object
        },
        data() {
            return {
                showAlert: false,
                alertType: null,
                message: ''
            };
        },
        computed: {
            email: {
                get() {
                    return this.member.email.work ? this.member.email.work : this.member.email.home;
                },
                set(value) {
                    if (this.member.email.work) {
                        this.member.email.work = value;
                    } else {
                        this.member.email.work = value;
                        this.member.email.home = value;
                    }
                }
            }
        },
        methods: {
            editProfile: function () {
                let route = this.member.zuora_id;

                if (!this.member.phone.home) {
                    delete this.member.phone.home;
                }
                if (!this.member.phone.cell) {
                    delete this.member.phone.cell;
                }
                if (!this.member.phone.work) {
                    delete this.member.phone.work;
                }

                if (this.member.is_secondary) {
                    route = route + '/' + this.member.member_number;
                }

                this.axios.put('/usls/members/' + route, this.member)
                    .then(response => {
                        this.showAlert = true;
                        if (!response.data.success) {
                            this.alertType = 'danger';
                            this.message = 'Unsuccessfully updated. Please try again later.';
                            throw "Unsuccessfully updated";
                        }

                        // Store the updated member info.
                        localStorage.setItem('member', JSON.stringify(this.member));
                        this.alertType = 'success';
                        this.message = 'Successfully updated your profile.';
                    })
                    .catch(error => {
                        this.showAlert = true;
                        this.alertType = 'danger';
                        this.message = error;
                    });
            }
        }
    }
</script>

<style scoped>

    .phone-number-input {
        width: 80%;
    }

</style>
