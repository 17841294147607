<template>
    <!-- begin:: Content -->
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="kt-portlet kt-iconbox kt-iconbox--brand" style="background-color: #F0F5FF;">
                    <div class="kt-portlet__body">
                        <div class="kt-iconbox__body">
                            <div class="kt-iconbox__icon">
                                <svg class="kt-svg-icon" height="24px"
                                     version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg"
                                     xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                                        <rect height="24" id="bound" width="24" x="0" y="0"/>
                                        <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                                              fill="#000000" id="Combined-Shape" opacity="0.3"/>
                                        <path d="M10.875,15.75 C10.6354167,15.75 10.3958333,15.6541667 10.2041667,15.4625 L8.2875,13.5458333 C7.90416667,13.1625 7.90416667,12.5875 8.2875,12.2041667 C8.67083333,11.8208333 9.29375,11.8208333 9.62916667,12.2041667 L10.875,13.45 L14.0375,10.2875 C14.4208333,9.90416667 14.9958333,9.90416667 15.3791667,10.2875 C15.7625,10.6708333 15.7625,11.2458333 15.3791667,11.6291667 L11.5458333,15.4625 C11.3541667,15.6541667 11.1145833,15.75 10.875,15.75 Z"
                                              fill="#000000" id="check-path"/>
                                        <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                                              fill="#000000" id="Combined-Shape"/>
                                    </g>
                                </svg>

                            </div>
                            <div class="kt-iconbox__desc">
                                <h3 class="kt-iconbox__title">What To Do After A Self-defense Incident</h3>
                                <div class="kt-iconbox__content">
                                    The moments after you have used a gun or other legal weapon are critical. If you do
                                    or say the incorrect thing, it may mean the difference between keeping your legal
                                    rights and waiving them. These legal rights may ultimately be the difference between
                                    preserving your freedom, or not! Therefore, Independent Program Attorneys have
                                    recommended that our Members follow these procedures if they have used a gun.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="kt-sc-faq-3 kt-negative-spacing"></div>
        <!--begin::Accordion-->
        <div class="kt-sc-faq-3 kt-sc-faq-3--accordion">
            <div class="accordion accordion-solid  accordion-toggle-svg" id="faq">
                <div class="card">
                    <div @click.prevent="one = !one" class="card-header" id="faqHeading1">
                        <div :class=" one ? '' : 'collapsed'" class="card-title">
                            1. Make sure the threat has been controlled and you are safe
                            <svg class="kt-svg-icon" height="24px"
                                 version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg"
                                 xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                                    <polygon id="Shape" points="0 0 24 0 24 24 0 24"/>
                                    <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                          fill="#000000" fill-rule="nonzero" id="Path-94"/>
                                    <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                          fill="#000000" fill-rule="nonzero" id="Path-94" opacity="0.3"
                                          transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "/>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div :class=" one ? '' : 'collapse'" id="faq1">
                        <div class="card-body">
                            Return the weapon to a safe place, if possible.
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div @click.prevent="two = !two" class="card-header" id="faqHeading2">
                        <div :class=" two ? '' : 'collapsed'" class="card-title">
                            2. Do not disturb the scene
                            <svg class="kt-svg-icon" height="24px"
                                 version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg"
                                 xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                                    <polygon id="Shape" points="0 0 24 0 24 24 0 24"/>
                                    <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                          fill="#000000" fill-rule="nonzero" id="Path-94"/>
                                    <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                          fill="#000000" fill-rule="nonzero" id="Path-94" opacity="0.3"
                                          transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "/>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div :class=" two ? '' : 'collapse'" id="faq2">
                        <div class="card-body">
                            Don’t tamper with or disturb evidence.
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div @click.prevent="three = !three" class="card-header" id="faqHeading3">
                        <div :class=" three ? '' : 'collapsed'" class="card-title">
                            3. Call 911
                            <svg class="kt-svg-icon" height="24px"
                                 version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg"
                                 xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                                    <polygon id="Shape" points="0 0 24 0 24 24 0 24"/>
                                    <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                          fill="#000000" fill-rule="nonzero" id="Path-94"/>
                                    <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                          fill="#000000" fill-rule="nonzero" id="Path-94" opacity="0.3"
                                          transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "/>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div :class=" three ? '' : 'collapse'" id="faq3">
                        <div class="card-body">
                            <ul>
                                <li>Dial 911 and request medical and police assistance.</li>
                                <li>The only information the 911 dispatcher needs to know is:
                                    <ul>
                                        <li>What assistance is needed;</li>
                                        <li>Your name and location of the emergency; and</li>
                                        <li>That you have been the victim of a crime.</li>
                                    </ul>
                                </li>
                                <li>Do not make any statements to the dispatcher about what happened.</li>
                                <li>You do not have to stay on the line with the 911</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div @click.prevent="four = !four" class="card-header" id="faqHeading4">
                        <div :class=" four ? '' : 'collapsed'" class="card-title">
                            4. Call the 24/7/365 attorney answered emergency hotline
                            <svg class="kt-svg-icon" height="24px"
                                 version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg"
                                 xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                                    <polygon id="Shape" points="0 0 24 0 24 24 0 24"/>
                                    <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                          fill="#000000" fill-rule="nonzero" id="Path-94"/>
                                    <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                          fill="#000000" fill-rule="nonzero" id="Path-94" opacity="0.3"
                                          transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "/>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div :class=" four ? '' : 'collapse'" id="faq4">
                        <div class="card-body">
                            <ul>
                                <li>Talk to your U.S. LawShield® Independent Program Attorney before you make any
                                    statements to the police about an incident or shooting.
                                </li>
                                <li>Follow all instructions given by your Independent Program Attorney.</li>
                            </ul>
                            <div class="text-right">
                                <a class="btn btn-danger" href="tel:18774747195" type="button"><i
                                        class="fa fa-phone-alt"></i> Call Now </a>&nbsp;
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div @click.prevent="five = !five" class="card-header" id="faqHeading5">
                        <div :class=" five ? '' : 'collapsed'" class="card-title">
                            5. Comply with all police commands
                            <svg class="kt-svg-icon" height="24px"
                                 version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg"
                                 xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                                    <polygon id="Shape" points="0 0 24 0 24 24 0 24"/>
                                    <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                          fill="#000000" fill-rule="nonzero" id="Path-94"/>
                                    <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                          fill="#000000" fill-rule="nonzero" id="Path-94" opacity="0.3"
                                          transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "/>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div :class=" five ? '' : 'collapse'" id="faq5">
                        <div class="card-body">
                            <ul>
                                <li>Police do not know who the good guys and bad guys are when they arrive. Comply in a
                                    nonthreatening manner with your hands clearly visible and your weapon secure.
                                </li>
                                <li>Inform police you have been the victim of a crime.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div @click="six = !six" class="card-header" id="faqHeading6">
                        <div :class=" six ? '' : 'collapsed'" class="card-title">
                            6. Invoke your legal rights
                            <svg class="kt-svg-icon" height="24px"
                                 version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg"
                                 xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                                    <polygon id="Shape" points="0 0 24 0 24 24 0 24"/>
                                    <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                          fill="#000000" fill-rule="nonzero" id="Path-94"/>
                                    <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                          fill="#000000" fill-rule="nonzero" id="Path-94" opacity="0.3"
                                          transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "/>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div :class=" six ? '' : 'collapse'" id="faq6">
                        <div class="card-body">
                            In an emergency situation, simply say these words to any law enforcement officer:
                            <blockquote>“I do not consent to any searches. I am invoking my right to remain silent and
                                my right to an attorney.”
                            </blockquote>
                            <ul>
                                <li>Make no statement to anyone about the incident.</li>
                                <li>Wait to talk to your Independent Program Attorney.</li>
                                <li>If asked to accompany law enforcement, comply but make no statements.</li>
                                <li>Do not make jokes or cute remarks.</li>
                                <li>Do not say anything, even if you believe you have done nothing wrong.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--end::Accordion-->
    </div>
    <!-- end:: Content -->
</template>

<script>
    export default {
        name: "WhatToDoAfter",
        data() {
            return {
                one: false,
                two: false,
                three: false,
                four: false,
                five: false,
                six: false,
            }
        }
    }
</script>

<style scoped>

</style>