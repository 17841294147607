<template>
    <section id="edit_profile">

        <!-- begin:: Content Head -->
        <div class="kt-subheader  kt-grid__item" id="kt_subheader">
            <div class="kt-container  kt-container--fluid ">
                <div class="kt-subheader__main">
                    <button @click="toggleMobileMenu = true" class="kt-subheader__mobile-toggle"
                            id="kt_subheader_mobile_toggle"><span></span></button>
                </div>
                <div class="kt-subheader__toolbar">
                </div>
            </div>
        </div>
        <!-- end:: Content Head -->

        <!-- begin:: Content -->
        <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

            <!--Begin::App-->
            <div class="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">

                <!--Begin:: App Aside-->
                <div
                        :class="{'kt-app__aside--on': toggleMobileMenu}"
                        class="kt-grid__item kt-app__toggle kt-app__aside"
                        id="kt_user_profile_aside"
                >
                    <!--Begin:: App Aside Mobile Toggle-->
                    <button @click="toggleMobileMenu = false" class="kt-app__aside-close"
                            id="kt_user_profile_aside_close">
                        <i class="la la-close"/>
                    </button>
                    <!--End:: App Aside Mobile Toggle-->

                    <!--begin:: Widgets/Applications/User/Profile1-->
                    <div class="kt-portlet ">
                        <div class="kt-portlet__body kt-portlet__body--fit-y">
                            <!--begin::Widget -->
                            <div class="kt-widget kt-widget--user-profile-1" style="height: 500px" v-if="!member"></div>
                            <div class="kt-widget kt-widget--user-profile-1" v-if="member">
                                <div class="kt-widget__head">
                                    <div class="kt-widget__content">
                                        <div class="kt-widget__section">
                                            <a class="kt-widget__username" href="#">
                                                <!-- ZuoraId: -->
                                                {{ member.firstName }} {{ member.lastName}}
                                                <i class="flaticon2-correct kt-font-success"><span
                                                        style="display: none">{{ member.zuora_id }}</span></i>
                                            </a>
                                            <span class="kt-widget__subtitle">
                                                {{ member.status }}
                                            </span>
                                        </div>

                                        <div class="kt-widget__action" v-if="member.status !== 'Active'">
                                            <button class="btn btn-success btn-sm" type="button">Activate</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="kt-widget__body">
                                    <div class="kt-widget__content">
                                        <div class="kt-widget__info">
                                            <span class="kt-widget__label">Email:</span>
                                            {{ member.email.work || member.email.home }}
                                        </div>
                                        <div class="kt-widget__info">
                                            <span class="kt-widget__label">Phone:</span>
                                            {{ member.phone.home || member.phone.cell || member.phone.work }}
                                        </div>
                                        <div class="kt-widget__info">
                                            <span class="kt-widget__label">Location:</span>
                                            <span class="kt-widget__data">{{ location }}</span>
                                        </div>
                                        <div class="kt-widget__info">
                                            <span class="kt-widget__label">Member #:</span>
                                            {{ member.member_number }}
                                        </div>
                                    </div>
                                    <div class="kt-widget__items">
                                        <router-link class="kt-widget__item"
                                                     exact-active-class="kt-widget__item--active"
                                                     id="personal_info_link"
                                                     to="/profile/personal-info"
                                        >
                                            <span class="kt-widget__section">
                                                <span class="kt-widget__icon">
                                                    <svg class="kt-svg-icon" height="24px"
                                                         version="1.1" viewBox="0 0 24 24" width="24px"
                                                         xmlns="http://www.w3.org/2000/svg"
                                                         xmlns:xlink="http://www.w3.org/1999/xlink">
                                                        <g fill="none" fill-rule="evenodd" stroke="none"
                                                           stroke-width="1">
                                                            <polygon id="Shape" points="0 0 24 0 24 24 0 24"/>
                                                            <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                                                                  fill="#000000" fill-rule="nonzero" id="Mask"
                                                                  opacity="0.3"/>
                                                            <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                                                                  fill="#000000" fill-rule="nonzero" id="Mask-Copy"/>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <span class="kt-widget__desc">
                                                    Personal Information
                                                </span>
                                            </span>
                                        </router-link>
                                        <router-link class="kt-widget__item"
                                                     exact-active-class="kt-widget__item--active"
                                                     id="address_info_link"
                                                     to="/profile/address-info">
                                            <span class="kt-widget__section">
                                                <span class="kt-widget__icon">
                                                    <svg class="kt-svg-icon" height="24px" version="1.1"
                                                         viewBox="0 0 24 24" width="24px"
                                                         xmlns="http://www.w3.org/2000/svg"
                                                         xmlns:xlink="http://www.w3.org/1999/xlink">
                                                        <g fill="none" fill-rule="evenodd" stroke="none"
                                                           stroke-width="1">
                                                            <rect height="24" width="24" x="0" y="0"/>
                                                            <path d="M3.95709826,8.41510662 L11.47855,3.81866389 C11.7986624,3.62303967 12.2013376,3.62303967 12.52145,3.81866389 L20.0429,8.41510557 C20.6374094,8.77841684 21,9.42493654 21,10.1216692 L21,19.0000642 C21,20.1046337 20.1045695,21.0000642 19,21.0000642 L4.99998155,21.0000673 C3.89541205,21.0000673 2.99998155,20.1046368 2.99998155,19.0000673 C2.99998155,19.0000663 2.99998155,19.0000652 2.99998155,19.0000642 L2.99999828,10.1216672 C2.99999935,9.42493561 3.36258984,8.77841732 3.95709826,8.41510662 Z"
                                                                  fill="#000000" opacity="0.3"/>
                                                            <path d="M13.8,12 C13.1562,12 12.4033,12.7298529 12,13.2 C11.5967,12.7298529 10.8438,12 10.2,12 C9.0604,12 8.4,12.8888719 8.4,14.0201635 C8.4,15.2733878 9.6,16.6 12,18 C14.4,16.6 15.6,15.3 15.6,14.1 C15.6,12.9687084 14.9396,12 13.8,12 Z"
                                                                  fill="#000000" opacity="0.3"/>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <span class="kt-widget__desc">
                                                    Address Information
                                                </span>
                                            </span>
                                        </router-link>
                                        <router-link class="kt-widget__item"
                                                     exact-active-class="kt-widget__item--active"
                                                     id="change_password_link"
                                                     to="/profile/change-password">
                                            <span class="kt-widget__section">
                                                <span class="kt-widget__icon">
                                                    <svg class="kt-svg-icon" height="24px"
                                                         version="1.1" viewBox="0 0 24 24" width="24px"
                                                         xmlns="http://www.w3.org/2000/svg"
                                                         xmlns:xlink="http://www.w3.org/1999/xlink">
                                                        <g fill="none" fill-rule="evenodd" stroke="none"
                                                           stroke-width="1">
                                                            <rect height="24" id="bound" width="24" x="0" y="0"/>
                                                            <path d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z"
                                                                  fill="#000000" id="Path-50" opacity="0.3"/>
                                                            <path d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z"
                                                                  fill="#000000" id="Mask" opacity="0.3"/>
                                                            <path d="M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 C14.5228466,17 11.463736,17 7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z"
                                                                  fill="#000000" id="Mask-Copy" opacity="0.3"/>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <span class="kt-widget__desc">
                                                    Change Password
                                                </span>
                                            </span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <!--end::Widget -->
                        </div>
                    </div>
                    <!--end:: Widgets/Applications/User/Profile1-->

                </div>
                <div @click="toggleMobileMenu = false" class="kt-app__aside-overlay" v-show="toggleMobileMenu"></div>
                <!--End:: App Aside-->

                <!--Begin:: App Content-->
                <div class="kt-grid__item kt-grid__item--fluid kt-app__content" style="margin-top: -20px">.
                    <keep-alive>
                        <router-view
                                :member="member"
                        ></router-view>
                    </keep-alive>
                </div>
                <!--End:: App Content-->
            </div>
            <!--End::App-->
        </div>
        <!-- end:: Content -->
    </section>
</template>

<script>
    export default {
        props: {
            member: Object,
        },
        data() {
            return {
                toggleMobileMenu: false
            }
        },
        computed: {
            location: function () {
                return this.member.address.city + ', ' + this.member.address.state;
            }
        }
    }
</script>
