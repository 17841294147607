<template>
    <!--begin:: Modal Edit/Add Card -->
    <!--        <transition></transition>-->
    <div aria-hidden="true" aria-labelledby="exampleModalLabel" id="add_credit_card">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Billing
                        Information:</h5>
                    <button aria-label="Close" class="close" type="button">
                    </button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="kt-section">
                            <div class="kt-section__content">
                              <div class="form-group row">
                                <div class="col-lg-12 form-group-sub">
                                  <div id="iframe-wrapper" class="iframe-wrapper" style="block">
                                    <iframe id="zuora-payment-page-address" :src="hostedPageURL"
                                            height="800px" width="100%" style="display:block;border:0;"></iframe>
                                  </div>
                                </div>
                              </div>

                                <div class="form-group form-group-last row">
                                    <div class="col-lg-12">
                                        <label class="kt-checkbox">
                                            <input
                                                    @click="autoPay && defaultPaymentMethod === true ? autoPay = false : null"
                                                    name="checkbox"
                                                   type="checkbox" v-model="defaultPaymentMethod"> Set as
                                            Default Payment
                                            <span></span>
                                        </label>
                                    </div>
                                    <div class="col-lg-12">
                                        <label class="kt-checkbox">
                                            <input @click="defaultPaymentMethod = true" name="checkbox"
                                                   type="checkbox" v-model="autoPay"> Please Enroll me in Autopay. By
                                            checking this box, I hearby authorize U.S. & Texas LawShield to
                                            automatically charge my card for all future payments.
                                            <span></span>
                                        </label>
                                    </div>
                                    <div class="col-lg-12">
                                        <p style='color:rgb(217,83,79);'>{{ error }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button @click="close"
                            class="btn btn-secondary"
                            type="button">Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!--end:: Modal Edit/Add Card -->
</template>

<script>
    export default {
        name: "CCModal",
        props: {
            member: Object
        },
        computed: {
        hostedPageURL: function () {
          return this.$tlsURL + '/hostedPageAddress';
          }
        },
        mounted(){
            this.fetchCommunicationProfiles();
            window.addEventListener('message', this.receiveMessage);
        },
        beforeDestroy () {
          window.removeEventListener('message', this.receiveMessage)
        },
        data() {
            return {
                communicationProfiles: [],
                autoPay: false,
                defaultPaymentMethod: false,
                hostedPaymentMethodID: '',
                paymentMethodAdded: false,
                defaultCard: {
                  accountID: this.member.zuora_id,
                  paymentMethodID: '',
                  isDefault: true
                },
                stdCard: {
                  accountKey: this.member.zuora_id
              }
            }
        },
        methods: {
            close: function () {
                this.$emit('close');
            },
            receiveMessage: function (e) {
              if (typeof e.data !== 'object' && typeof e.data === 'string'
                  && !e.data.includes("setImmediate") && this.paymentMethodAdded === false
                  && e.data.length === 32) {

                this.hostedPaymentMethodID = e.data;
                this.paymentMethodAdded = true;
                this.addCard();
              }
            },
            addCard: function () {
                if (this.defaultPaymentMethod === true) {
                    this.defaultCard.paymentMethodID = this.hostedPaymentMethodID;
                    this.axios.post("/zuora/paymentMethod/attachToAccount", this.defaultCard)
                      .then(() => {
                        this.toggleAutoPay();
                        this.$parent.$emit('cardAdded', this.defaultCard);
                        this.close();
                        location.reload();
                      })
                      .catch(error => {
                        console.warn(error);
                        this.error = "Something went wrong. Please try again later.";
                        if (error && error.response && error.response.status === 400) {
                          this.error = "Please check your information and try again.";
                        }
                      });
                    return;
                } else if (this.defaultPaymentMethod === false) {
                  this.axios.put("/zuora/paymentMethod/" + this.hostedPaymentMethodID, this.stdCard)
                      .then(() => {
                        this.toggleAutoPay();
                        this.$parent.$emit('cardAdded', this.stdCard);
                        this.close();
                        location.reload();
                      })
                      .catch(error => {
                        console.warn(error);
                        this.error = "Something went wrong. Please try again later.";
                        if (error && error.response && error.response.status === 400) {
                          this.error = "Please check your information and try again.";
                        }
                      });
                  return;
                }
            },
            fetchCommunicationProfiles: function () {
                this.axios.get('/zuora/communicationProfiles')
                .then(response => {
                    this.communicationProfiles = response.data;
                })
                .catch(error => {
                    console.warn(error);
                })
            },
            toggleAutoPay: function () {
                let _this = this;
                const currentProfile = this.communicationProfiles.find(function (profile) {
                    return profile.Id === _this.member.communicationProfileId;
                });

                let profileName = currentProfile.ProfileName.replace('Autopay', 'Invoice');
                if (this.autoPay){
                    profileName = currentProfile.ProfileName.replace('Invoice', 'Autopay');
                }

                const newProfile = this.communicationProfiles.find(function (profile) {
                    return profile.ProfileName === profileName;
                });
                this.axios.patch("/zuora/members/" + this.member.zuora_id, {
                    autoPay: this.autoPay,
                    communicationProfileId : newProfile.Id
                })
                .then(() => {
                    this.member.communicationProfileId = newProfile.Id;
                    localStorage.setItem('member', JSON.stringify(this.member));
                })
                .catch(error => {
                    console.warn(error)
                });

            }
        }
    }
</script>

<style scoped>

</style>