"use strict";

export class SubscriptionRepo {

    constructor(axios, zuora_id) {
        this.axios = axios;
        this.zuora_id = zuora_id;
        this.subscriptions = null;
    }

    get() {
        return this.subscriptions;
    }

    fetchInfo() {
        return new Promise((resolve, reject) => {
        this.axios.get('/zuora/members/' + this.zuora_id + '/subscription')
            .then(response => {
                this.subscriptions = response.data;
                resolve(this.subscriptions);
            })
            .catch(error => {
                reject(error);
            });
        });
    }
}
