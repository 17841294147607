<template>
    <section id="address_info">
        <div class="row">
            <div class="col-xl-12">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">Address Information <small>change your address
                                settings</small></h3>
                        </div>
                    </div>
                    <form @submit.prevent="editAddress" class="kt-form kt-form--label-right">
                        <div class="kt-portlet__body">
                            <div class="kt-section kt-section--first">
                                <div class="kt-section__body">
                                    <div class="row">
                                        <label class="col-xl-3"></label>
                                        <div class="col-lg-9 col-xl-6">
                                            <h3 class="kt-section__title kt-section__title-sm">Account Address:</h3>
                                        </div>
                                    </div>


                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label" for="address1">Address</label>
                                        <div class="col-lg-9 col-xl-6">
                                            <input class="form-control" name="address1" id="address1" placeholder="Address Line 1"
                                                   type="text" v-model="member.address.address1">
                                        </div>
                                    </div>
                                    <div class="form-group row" v-if="false">
                                        <label class="col-xl-3 col-lg-3 col-form-label" for="address2">Address Line 2</label>
                                        <div class="col-lg-9 col-xl-6">
                                            <input class="form-control" id="address2" name="address2" placeholder="Address Line 2"
                                                   type="text" v-model="member.address.address2">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label" for="city">City</label>
                                        <div class="col-lg-9 col-xl-6">
                                            <input class="form-control" name="city" id="city" placeholder="City" type="text"
                                                   v-model="member.address.city">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label" for="zip_code">Zip code</label>
                                        <div class="col-lg-9 col-xl-6">
                                            <input class="form-control" name="zipcode" id="zip_code" placeholder="Zip Code"
                                                   type="text" v-model="member.address.zipcode">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label" for="state">State</label>
                                        <div class="col-lg-9 col-xl-6">
                                            <select :disabled="state.show"
                                                    id="state"
                                                    :value="address.state"
                                                    @change="stateChange(address, $event)"
                                                    class="form-control kt-selectpicker" data-size="4">
                                                <option v-bind:data-region="index" v-bind:key="index"
                                                        v-bind:value="state.name"
                                                        v-for="(state, index) in states">{{ state.name }}
                                                </option>
                                            </select>
                                            <alert :class="'col-lg-9'" :message="state.message"
                                                   :show="state.show"
                                                   type="danger" v-on:close-alert="state.show = blockSubmit = false"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="kt-portlet__foot">
                            <div class="kt-form__actions">
                                <div class="row">
                                    <div class="col-lg-3 col-xl-3">
                                    </div>
                                    <div class="col-lg-9 col-xl-9">
                                        <alert :class="'col-lg-6'" :message="message"
                                               :show="showAlert" :type="alertType"
                                               v-on:close-alert="showAlert = false"/>
                                        <button :disabled="blockSubmit" class="btn btn-brand btn-bold">Submit</button>&nbsp;
                                        <button class="btn btn-secondary" type="reset">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        props: {
            member: Object
        },
        data() {
            return {
                showAlert: false,
                alertType: null,
                message: '',
                blockSubmit: false,
                state: {
                    message: "If you wish to change your state of residence, please call us at <a href=\"tel:877-448-6839\">(877) 448-6839</a> and we'll help you get it updated!",
                    show: false,
                },
                states:
                    [
                        {
                            "name": "Alabama",
                            "abbreviation": "AL"
                        },
                        {
                            "name": "Alaska",
                            "abbreviation": "AK"
                        },
                        {
                            "name": "American Samoa",
                            "abbreviation": "AS"
                        },
                        {
                            "name": "Arizona",
                            "abbreviation": "AZ"
                        },
                        {
                            "name": "Arkansas",
                            "abbreviation": "AR"
                        },
                        {
                            "name": "California",
                            "abbreviation": "CA"
                        },
                        {
                            "name": "Colorado",
                            "abbreviation": "CO"
                        },
                        {
                            "name": "Connecticut",
                            "abbreviation": "CT"
                        },
                        {
                            "name": "Delaware",
                            "abbreviation": "DE"
                        },
                        {
                            "name": "District Of Columbia",
                            "abbreviation": "DC"
                        },
                        {
                            "name": "Federated States Of Micronesia",
                            "abbreviation": "FM"
                        },
                        {
                            "name": "Florida",
                            "abbreviation": "FL"
                        },
                        {
                            "name": "Georgia",
                            "abbreviation": "GA"
                        },
                        {
                            "name": "Guam",
                            "abbreviation": "GU"
                        },
                        {
                            "name": "Hawaii",
                            "abbreviation": "HI"
                        },
                        {
                            "name": "Idaho",
                            "abbreviation": "ID"
                        },
                        {
                            "name": "Illinois",
                            "abbreviation": "IL"
                        },
                        {
                            "name": "Indiana",
                            "abbreviation": "IN"
                        },
                        {
                            "name": "Iowa",
                            "abbreviation": "IA"
                        },
                        {
                            "name": "Kansas",
                            "abbreviation": "KS"
                        },
                        {
                            "name": "Kentucky",
                            "abbreviation": "KY"
                        },
                        {
                            "name": "Louisiana",
                            "abbreviation": "LA"
                        },
                        {
                            "name": "Maine",
                            "abbreviation": "ME"
                        },
                        {
                            "name": "Marshall Islands",
                            "abbreviation": "MH"
                        },
                        {
                            "name": "Maryland",
                            "abbreviation": "MD"
                        },
                        {
                            "name": "Massachusetts",
                            "abbreviation": "MA"
                        },
                        {
                            "name": "Michigan",
                            "abbreviation": "MI"
                        },
                        {
                            "name": "Minnesota",
                            "abbreviation": "MN"
                        },
                        {
                            "name": "Mississippi",
                            "abbreviation": "MS"
                        },
                        {
                            "name": "Missouri",
                            "abbreviation": "MO"
                        },
                        {
                            "name": "Montana",
                            "abbreviation": "MT"
                        },
                        {
                            "name": "Nebraska",
                            "abbreviation": "NE"
                        },
                        {
                            "name": "Nevada",
                            "abbreviation": "NV"
                        },
                        {
                            "name": "New Hampshire",
                            "abbreviation": "NH"
                        },
                        {
                            "name": "New Jersey",
                            "abbreviation": "NJ"
                        },
                        {
                            "name": "New Mexico",
                            "abbreviation": "NM"
                        },
                        {
                            "name": "New York",
                            "abbreviation": "NY"
                        },
                        {
                            "name": "North Carolina",
                            "abbreviation": "NC"
                        },
                        {
                            "name": "North Dakota",
                            "abbreviation": "ND"
                        },
                        {
                            "name": "Northern Mariana Islands",
                            "abbreviation": "MP"
                        },
                        {
                            "name": "Ohio",
                            "abbreviation": "OH"
                        },
                        {
                            "name": "Oklahoma",
                            "abbreviation": "OK"
                        },
                        {
                            "name": "Oregon",
                            "abbreviation": "OR"
                        },
                        {
                            "name": "Palau",
                            "abbreviation": "PW"
                        },
                        {
                            "name": "Pennsylvania",
                            "abbreviation": "PA"
                        },
                        {
                            "name": "Puerto Rico",
                            "abbreviation": "PR"
                        },
                        {
                            "name": "Rhode Island",
                            "abbreviation": "RI"
                        },
                        {
                            "name": "South Carolina",
                            "abbreviation": "SC"
                        },
                        {
                            "name": "South Dakota",
                            "abbreviation": "SD"
                        },
                        {
                            "name": "Tennessee",
                            "abbreviation": "TN"
                        },
                        {
                            "name": "Texas",
                            "abbreviation": "TX"
                        },
                        {
                            "name": "Utah",
                            "abbreviation": "UT"
                        },
                        {
                            "name": "Vermont",
                            "abbreviation": "VT"
                        },
                        {
                            "name": "Virgin Islands",
                            "abbreviation": "VI"
                        },
                        {
                            "name": "Virginia",
                            "abbreviation": "VA"
                        },
                        {
                            "name": "Washington",
                            "abbreviation": "WA"
                        },
                        {
                            "name": "Washington DC",
                            "abbreviation": "DC"
                        },
                        {
                            "name": "West Virginia",
                            "abbreviation": "WV"
                        },
                        {
                            "name": "Wisconsin",
                            "abbreviation": "WI"
                        },
                        {
                            "name": "Wyoming",
                            "abbreviation": "WY"
                        }
                    ],
            }
        },
        methods: {
            stateChange(address, event) {
                this.state.show = true;
                this.blockSubmit = event.target.value !== address.state;
            },
            editAddress() {
                this.axios.patch('/zuora/members/' + this.member.zuora_id, {soldToContact: this.address})
                    .then(response => {
                        this.showAlert = true;
                        if (!response.data.success) {
                            this.alertType = 'danger';
                            if(response.data.soldToContact){
                                this.message = "The Address you have entered is invalid.";
                                throw this.message;
                            }
                            this.message = "Unsuccessfully updated. If this error continues please contact us.";
                            throw this.message;
                        }
                        this.alertType = 'success';
                        this.message = "Account Address was successfully updated.";
                    })
                    .catch(error => {
                        this.showAlert = true;
                        this.alertType = 'danger';
                        this.message = error;
                    });
            }
        },

        computed: {
            address: function () {
                return {
                    firstName: this.member.firstName,
                    lastName: this.member.lastName,
                    address1: this.member.address.address1,
                    address2: '',
                    city: this.member.address.city,
                    state: this.member.address.state,
                    zipCode: this.member.address.zipcode
                }
            }
        }
    }
</script>

<style scoped>

</style>
