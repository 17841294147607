<template>
    <div class="kt-header-mobile kt-header-mobile--fixed " id="kt_header_mobile">
        <div class="kt-header-mobile__logo">
            <router-link to="/">
                <img alt="Logo" src="/assets/media/logos/logo-2-sm.png"/>
            </router-link>
        </div>
        <div class="kt-header-mobile__toolbar">
            <button
                    :class="{'kt-header-mobile__toolbar-toggler--active' : toggleMobileMenus.aside}"
                    @click="toggleMobileAsideMenu"
                    class="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left"
                    id="kt_aside_mobile_toggler"><span></span></button>
            <button class="kt-header-mobile__toolbar-toggler" id="kt_header_mobile_toggler" style="display: none;">
                <span></span></button>
            <button
                    @click="toggleMobileUserMenu"
                    :class="{'kt-header-mobile__toolbar-topbar-toggler--active' : toggleMobileMenus.user}"
                    class="kt-header-mobile__toolbar-topbar-toggler"
                    id="kt_header_mobile_topbar_toggler">
                <i class="flaticon-more"></i></button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MobileHeader",
        data() {
            return {
                toggleMobileMenus: {
                    aside: false,
                    user: false
                }
            }
        },
        methods: {
            toggleMobileAsideMenu: function () {
                this.$emit('toggle-mobile-aside');
            },
            toggleMobileUserMenu: function () {
                this.$emit('toggle-mobile-user');
            }
        }
    }
</script>

<style scoped>

</style>