<template>
    <section id="base">
        <MobileHeader :show-facility-portal="showFacilityPortal"
                      :toggleMobileMenus="toggleMobileMenus"
                      @toggle-mobile-aside="toggleMobileAsideMenu"
                      @toggle-mobile-user="toggleMobileUserMenu"/>
        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <SideMenu :is_secondary="isSecondaryMember" :show-facility-portal="showFacilityPortal"
                          :toggleMobileAside="toggleMobileMenus.aside"
                          @closeMobileAside="toggleMobileAsideMenu"/>
                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper"
                     style="min-height: 100vh;">
                    <Header :member="member" :toggle-mobile-user="toggleMobileMenus.user" :show-facility-portal="showFacilityPortal"/>
                    <div class="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                        <router-view :member="member" :subscriptions="subscriptions"/>
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
        <div class="kt-scrolltop" id="kt_scrolltop"><i class="fa fa-arrow-up"/></div>
    </section>
</template>

<script>
    import Header from "./partials/Header";
    import MobileHeader from "./partials/MobileHeader";
    import Footer from "./partials/Footer";
    import SideMenu from "./partials/SideMenu";

    export default {
        name: 'Main',
        props: {
            member: Object,
            subscriptions: Array,
            showFacilityPortal: {
                type: Boolean,
                default: false
            }
        },
        components: {
            Header,
            MobileHeader,
            SideMenu,
            Footer
        },
        data() {
            return {
                // Component State
                loading: true,
                error: null,
                success: false,
                toggleMobileMenus: {
                    aside: false,
                    user: false
                }
            };
        },
        computed: {
            isSecondaryMember: function () {
                return !(!this.member || !this.member.is_secondary);
            }
        },
        methods: {
            toggleMobileAsideMenu: function () {
                this.toggleMobileMenus.aside = !this.toggleMobileMenus.aside;
                this.$emit('toggle-mobile-aside');
            },
            toggleMobileUserMenu: function () {
                this.toggleMobileMenus.user = !this.toggleMobileMenus.user;
                this.$emit('toggle-mobile-user');
            },
        }
    }
</script>

