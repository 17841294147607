import Vue from 'vue';
import VueCookies from 'vue-cookies';
import Router from 'vue-router';
import Login from '@/components/member/Login';
import ResetPassword from '@/components/member/ResetPassword';
// Components
import Main from "@/components/Main";
import Dashboard from "@/components/Dashboard";
import Coverage from "@/components/Coverage";
import LegacyCoverage from "@/components/LegacyCoverage";
import Events from "@/components/Events";
import Perks from "@/components/Perks";
import LegacyFacilityPortal from "@/components/LegacyFacilityPortal";
import EmergencyPlan from "@/components/eplan/EmergencyPlan";
import EmergencyAccess from "@/components/eplan/EmergencyAccess";
import WhatToDoAfter from "@/components/eplan/WhatToDoAfter";
import Profile from "@/components/member/Profile";
import PersonalInfo from "@/components/member/profile/PersonalInfo";
import AddressInfo from "@/components/member/profile/AddressInfo";
import ChangePassword from "@/components/member/profile/ChangePassword";
import Billing from "@/components/member/Billing";
import Invoices from "@/components/member/profile/Invoices";
import BillingAccount from "@/components/member/profile/BillingAccount";
import MakePayment from "@/components/member/profile/MakePayment";
import LegacyEducation from "@/components/LegacyEducation";
import Education from "@/components/Education";

Vue.use(Router);
Vue.use(VueCookies);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: Login,
            props: true,
            meta: {auth: false}
        },
        {
            path: '/forgot-password',
            name: 'Forgot Password',
            component: Login,
            props: {forgotPass: true},
            meta: {auth: false}
        },
        {
            path: '/reset-password/:token',
            component: ResetPassword,
            meta: {auth: false}
        },
        {
            path: '/',
            component: Main,
            meta: {auth: true},
            children: [
                {
                    path: '',
                    component: Dashboard,
                },
                {
                    path:'dashboard',
                    component: Dashboard,
                },
                {
                    path: 'coverageV2',
                    component: Coverage,
                },
                {
                    path: 'coverage',
                    component: LegacyCoverage,
                },
                {
                    path: 'LegacyEducation',
                    component: LegacyEducation,
                },
                {
                    path: 'education',
                    component: Education,
                },
                {
                    path: 'eplan',
                    redirect: '/eplan/emergency-plan-form'
                },
                {
                    path: 'events',
                    component: Events,
                },
                {
                    path: 'perks',
                    component: Perks,
                },
                {
                    path: 'facility-portal',
                    component: LegacyFacilityPortal,
                },
                {
                    path: 'eplan/emergency-plan-form',
                    component: EmergencyPlan,
                },
                {
                    path: 'eplan/what-to-do-after',
                    component: WhatToDoAfter,
                },
                {
                    path: 'eplan/emergency-access',
                    component: EmergencyAccess,
                },
                {
                    path: 'profile',
                    component: Profile,
                    children: [
                        {
                            path: '',
                            component: PersonalInfo,
                            alias: 'personal-info'
                        },
                        {
                            path: 'address-info',
                            component: AddressInfo
                        },
                        {
                            path: 'change-password',
                            component: ChangePassword
                        }
                    ]
                },
                {
                    path: 'billing',
                    component: Billing,
                    children: [
                        {
                            path: 'invoices',
                            component: Invoices
                        },
                        {
                            path: '',
                            component: BillingAccount,
                            alias: 'billing-account'
                        },
                        {
                            path: 'make-payment',
                            component: MakePayment
                        }
                    ]
                }
            ]
        }
    ]
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth === true)) {
        if (Vue.$cookies.get('jwt') == null) {
            next({
                path: '/login',
                params: {nextUrl: to.fullPath}
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
