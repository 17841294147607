<template>
    <!--begin:: Modal Edit/Add Card -->
    <!--        <transition></transition>-->
    <div aria-hidden="true" aria-labelledby="exampleModalLabel" id="cancelled-account-modal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Your Account Has Been Cancelled. Please contact
                        us.</h5>
                    <button aria-label="Close" class="close" type="button">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="kt-portlet__body">
                        <div class="kt-section">
                            <div class="kt-section__content">
                                <h4>
                                    <small class="text-muted">
                                        Your account had been cancelled. In order to re-activate you account please
                                        contact support at <a href="tel:877-448-6839">+1 (877) 448-6839</a> or go to our
                                        <a href="https://www.uslawshield.com/contact-us/">Contact Us</a> page. You will
                                        be redirected to our Contact Us page on pressing close. We apologize for any
                                        inconvenience and hope to hear from you soon.
                                    </small>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="close"
                            class="btn btn-primary"
                            type="button">Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!--end:: Modal Edit/Add Card -->
</template>

<script>

    export default {
        name: "CheckAccountStatus",
        methods: {
            close: function () {
                this.$emit('close');
                this.$parent.$emit('close-check');
            }
        }
    }
</script>

<style scoped>

</style>