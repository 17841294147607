<template>
    <section id="change_password">
        <div class="row centered">
            <div class="col-xl-12">
                <div class="kt-portlet kt-portlet--height-fluid">
                    <div class="kt-reset__logo">
                        <a href="https://www.uslawshield.com">
                            <img src="/assets/media/company-logos/logo-2.png">
                        </a>
                    </div>
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">Reset Password<small>reset your account
                                password</small></h3>
                        </div>
                    </div>
                    <validation-observer @submit.prevent="resetPassword" class="kt-form kt-form--label-right"
                                         ref="observer"
                                         tag="form" v-slot="{ invalid }">
                        <div class="kt-portlet__body">
                            <div class="kt-section kt-section--first">
                                <div class="kt-section__body">
                                    <div class="row">
                                        <label class="col-xl-3"></label>
                                        <div class="col-lg-9 col-xl-6">
                                            <h3 v-if="verified === false" style="margin-bottom: 10px">Verifying your password reset token...</h3>
                                            <alert :show="passwordChanged" message="Your password has been changed."
                                                   type="success" v-on:close-alert="passwordChanged = false"/>
                                            <alert :message="'Couldn\'t change your password: ' +  error + '.'"
                                                   :show="error"
                                                   :show-close="false"
                                                   type="danger"/>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label">New Password</label>
                                        <div class="col-lg-9 col-xl-6">
                                            <validation-provider name="password"
                                                                 rules="min:6"
                                                                 v-slot="{ errors }">
                                                <input :disabled="!verified" @change="validate"
                                                       class="form-control"
                                                       placeholder="New password"
                                                       type="password"
                                                       v-model="password">
                                                <span style='color:rgb(217,83,79);'>
                                                    {{ errors[0] }}
                                                </span>
                                            </validation-provider>
                                        </div>
                                    </div>
                                    <div class="form-group form-group-last row">
                                        <label class="col-xl-3 col-lg-3 col-form-label">Verify Password</label>
                                        <div class="col-lg-9 col-xl-6">
                                            <validation-provider name="confirmation"
                                                                 rules="required_if:password|confirm:password"
                                                                 v-slot="{ errors }">
                                                <input :disabled="!verified" @change="validate"
                                                       class="form-control"
                                                       placeholder="Verify password"
                                                       type="password"
                                                       v-model="verifyPass">
                                                <p style='color:rgb(217,83,79);'>
                                                    {{ errors[0] }}
                                                </p>
                                            </validation-provider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="kt-portlet__foot">
                            <div class="kt-form__actions">
                                <div class="row">
                                    <div class="col-lg-3 col-xl-3">
                                    </div>
                                    <div class="col-lg-9 col-xl-9">
                                        <button :disabled="password === '' || invalid"
                                                class="btn btn-brand btn-bold">Change Password
                                        </button>&nbsp;
                                        <button @click="requestToken" class="btn btn-secondary green" v-if="error">
                                            Request a new password reset token
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </validation-observer>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import {extend} from 'vee-validate';
    import {min, required_if} from 'vee-validate/dist/rules';

    extend('required_if', {
        ...required_if,
        message: 'You must verify your new password.'
    });

    extend('min', {
        ...min,
        message: 'Your password must be at least 6 characters long.'
    });

    extend('confirm', {
        validate: (value, {other}) => value === other,
        message: 'Your passwords do not match.',
        params: [{name: 'other', isTarget: true}]
    });

    export default {
        data: () => ({
            error: null,
            verified: false,
            zuora_id: null,
            password: '',
            verifyPass: '',
            isValid: false,
            passwordChanged: false
        }),
        computed: {},
        mounted() {
            this.verifyToken();
        },
        methods: {
            async validate() {
                this.isValid = await this.$refs.observer.validate();
            },
            // @todo: Move to Auth when we have time.
            verifyToken() {
                this.axios.get('/auth/members/tokens/' + this.$route.params.token)
                    .then(response => {
                        this.zuora_id = response.data.user_id;
                        this.verified = true;
                        this.email = response.data.email;
                    })
                    .catch(error => this.error = error.response.data.message);
            },
            resetPassword() {
                this.axios.put('/auth/members/' + this.email + '/password', {
                    reset_token: this.$route.params.token,
                    password: this.password,
                    password_confirmation: this.verifyPass
                })
                    .then(response => {
                        // Store the updated member info.
                        this.$auth.login(response.data.access_token, response.data.expires_in);
                        this.passwordChanged = true;

                        this.$router.push('/login').catch(() => {
                        });
                    })
                    .catch(error => {
                        this.error = error.response.data.message;
                        this.passwordChanged = false;
                    });
            },
            requestToken() {
                this.$router.push({name: 'Login', params: {forgotPass: true}});
            }
        }
    }
</script>

<style scoped>

    .centered {
        position: absolute;
        top: 25%;
        left: 25%;
        width: 50vw;
    }

    .kt-reset__logo {
        margin-top: 25px;
        position: relative;
        text-align: center;
    }

</style>