<template>
    <body
            :class="{
                'kt-page--loading': loading,
                'kt-aside--on': toggleMobileMenus.aside,
                'kt-header__topbar--mobile-on': toggleMobileMenus.user
                }"

            class="kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--enabled kt-subheader--fixed kt-subheader--solid kt-aside--enabled kt-aside--fixed "
    >
    <keep-alive>
        <router-view :member="member" :showFacilityPortal="showFacilityPortal" :subscriptions="subscriptions"
                     @loggedIn="loggedIn"
                     @toggle-mobile-aside="toggleMobileAsideMenu"
                     @toggle-mobile-user="toggleMobileUserMenu"
        />
    </keep-alive>
    </body>
</template>

<script>
    import {MemberRepo} from "@/repositories/MemberRepo";
    import {SubscriptionRepo} from "@/repositories/SubscriptionRepo";

    import ForcePasswordReset from "./components/partials/ForcePasswordReset";
    import ActiveBalance from "./components/partials/ActiveBalance";

    export default {
        name: 'app',
        data() {
            return {
                // Component State
                loading: true,
                error: null,
                success: false,
                toggleMobileMenus: {
                    aside: false,
                    user: false
                },
                showFacilityPortal: false,

                // Model State
                email: null,
                has_error: false,
                member: {
                    zuora_id: null,
                    status: null,
                    prefix: null,
                    firstName: '',
                    lastName: '',
                    suffix: null,
                    communicationProfileId: null,
                    is_secondary: false,
                    first_login: false,
                    email: {
                        home: null,
                        work: null
                    },
                    phone: {
                        home: null,
                        cell: null,
                        work: null,
                    }
                },
                subscriptions: [],
            };
        },
        computed: {
            isSecondaryMember: function () {
                return !(!this.member || !this.member.is_secondary);
            }
        },
        created() {
            // Refetch it from scratch.
            this.fetchMemberInfo(true);
            this.fetchSubscriptionInfo();
        },
        methods: {
            loggedIn: function () {
                this.fetchMemberInfo(true);
                this.fetchSubscriptionInfo();
            },
            fetchMemberInfo: function (noCache) {
                this.error = this.member = null;
                this.loading = true;
                this.zuora_id = localStorage.getItem('member:zuora_id');

                if (!this.zuora_id) {
                    return;
                }

                // Use the cache, if possible.
                const grabCachedMember = function () {
                    if (noCache) {
                        return null;
                    }

                    return JSON.parse(localStorage.getItem('member'));
                };

                const member = grabCachedMember();
                if (member) {
                    alert("using cached member");
                    this.member = member;
                    this.fetchDBMember();
                    return;
                }
                const memberRepo = new MemberRepo(this.axios, this.zuora_id);
                memberRepo.fetchInfo()
                    .then(member => {
                        this.member = member;
                        this.fetchDBMember();
                        localStorage.setItem('member', JSON.stringify(this.member));
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            this.$auth.logout();
                        }

                        console.warn(error);
                        this.error = error;
                    })
                    .finally(() => this.loading = false);
            },
            fetchSubscriptionInfo: function () {
                this.loading = true;
                this.zuora_id = localStorage.getItem('member:zuora_id');

                if (!this.zuora_id) {
                    return;
                }

                const subscriptionRepo = new SubscriptionRepo(this.axios, this.zuora_id);
                subscriptionRepo.fetchInfo()
                    .then(subscriptions => {
                        this.subscriptions = subscriptions;
                    })
                    .catch(error => {
                        console.warn(error);
                        this.error = error;
                    })
                    .finally(() => this.loading = false);
            },
            fetchDBMember: function () {
                if (!this.member || !this.member.member_number) {
                    return;
                }

                const memberRepo = new MemberRepo(this.axios, this.zuora_id);
                memberRepo.fetchBasicInfo(this.member.member_number)
                    .then(member => {
                        this.member.portals = member.portals;
                        this.member.is_active = Boolean(member.is_active);
                        this.member.first_login = member.first_login;
                        this.member.region_id = member.region_id;
                        if (member.portals.facility_portal) {
                            this.showFacilityPortal = true;
                        }
                        this.checkFirstLogin();
                        this.checkBalance();
                    })
                    .catch(error => {
                        console.warn(error);
                        this.error = error;
                    });
            },
            toggleMobileAsideMenu: function () {
                this.toggleMobileMenus.aside = !this.toggleMobileMenus.aside;
            },
            toggleMobileUserMenu: function () {
                this.toggleMobileMenus.user = !this.toggleMobileMenus.user;
            },
            checkFirstLogin: function () {
                if (this.member.first_login) {
                    let _this = this;
                    this.$modal.show(
                        ForcePasswordReset,
                        {
                            member: _this.member
                        },
                        {
                            name: 'password-reset-modal',
                            adaptive: true,
                            height: 'auto',
                            width: '45%',
                            scrollable: true,
                            clickToClose: false,
                        },
                        {
                            'password-changed': function () {
                                _this.member.first_login = false;
                            }
                        }
                    );
                }
            },
            checkBalance: function () {
                if (this.member.balance > 0 && !this.member.is_secondary &&
                    (window.location.pathname !== '/billing/make-payment')) {
                    let _this = this;
                    this.$modal.show(
                        ActiveBalance,
                        {
                            member: _this.member
                        },
                        {
                            name: 'active-account-balance',
                            adaptive: true,
                            height: 'auto',
                            width: '45%',
                            scrollable: true,
                            clickToClose: false,
                        }
                    );
                }
            }
        }
    }
</script>

