<template>
    <section id="login">
        <!-- begin:: Page -->
        <div class="kt-grid kt-grid--ver kt-grid--root kt-page" style="min-height: 100vh;">
            <div :class="forgotPass ? 'kt-login--forgot' : 'kt-login--signin'"
                 class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v6" id="kt_login">
                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
                    <div class="kt-grid__item  kt-grid__item--order-tablet-and-mobile-2  kt-grid kt-grid--hor kt-login__aside">
                        <div class="kt-login__wrapper">
                            <div class="kt-login__container">
                                <div class="kt-login__body">
                                    <div class="kt-login__logo">
                                        <a href="https://www.uslawshield.com" target="_blank">
                                            <img src="/assets/media/company-logos/logo-2.png">
                                        </a>
                                    </div>

                                    <div class="kt-login__signin" v-show="!forgotPass">
                                        <div class="kt-login__head">
                                            <h3 class="kt-login__title">Sign In To Member Portal</h3>
                                        </div>
                                        <div class="kt-login__form">
                                            <form class="kt-form">
                                                <div class="form-group">
                                                    <input class="form-control" id="kt_username" name="email"
                                                           placeholder="Email / Username" type="text" v-model="email"/>
                                                </div>
                                                <div class="form-group">
                                                    <input class="form-control form-control-last" id="kt_password"
                                                           name="password"
                                                           placeholder="Password" type="password" v-model="password"/>
                                                </div>
                                                <alert :message="loginError" :show="showLoginAlert"
                                                       type="danger" v-on:close-alert="showLoginAlert = false"/>
                                                <alert :show="token.length > 0"
                                                       message="Please wait as you are logged in."
                                                       type="primary" v-on:close-alert="token = ''"/>
                                                <div class="kt-login__extra">
                                                    <label class="kt-checkbox">
                                                        <input name="remember" type="checkbox"> Remember me
                                                        <span></span>
                                                    </label>
                                                    <a @click="forgotPass = true" id="kt_login_forgot">New Member or Forgot
                                                        Password?</a>
                                                </div>
                                                <div class="kt-login__actions">
                                                    <button @click="login"
                                                            class="btn btn-brand btn-pill btn-elevate"
                                                            id="kt_login_signin_submit">
                                                        Sign in
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="kt-login__forgot" v-show="forgotPass">
                                        <div class="kt-login__head">
                                            <h3 class="kt-login__title">Forgotten Password ?</h3>
                                            <div class="kt-login__desc">Enter your email to reset your password:</div>
                                        </div>
                                        <div class="kt-login__form">
                                            <form action="" class="kt-form">
                                                <div class="form-group">
                                                    <input autocomplete="off" class="form-control"
                                                           id="kt_email" name="email" placeholder="Email"
                                                           type="text" v-model="email"
                                                           v-on:keyup.enter.prevent="requestResetToken">
                                                </div>
                                                <alert :show="emailSent"
                                                       message="A password reset email has been sent to you.</br>Please check your email."
                                                       type="success" v-on:close-alert="emailSent = false"/>
                                                <alert :show="error"
                                                       message="A reset token could not be generated. Please verify that your email address is correct and try again."
                                                       type="danger" v-on:close-alert="error = false"/>
                                                <div class="kt-login__actions">
                                                    <button @click.prevent="requestResetToken"
                                                            class="btn btn-brand btn-pill btn-elevate"
                                                            id="kt_login_forgot_submit">Request
                                                    </button>
                                                    <button @click.prevent="forgotPass = false"
                                                            class="btn btn-outline-brand btn-pill"
                                                            id="kt_login_forgot_cancel">Cancel
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="kt-login__account">
                                <span class="kt-login__account-msg">
                                    Need help?
                                </span>&nbsp;&nbsp;
                                <a :href="hostURL + '/contact-us/'" class="kt-login__account-link" id="kt_login_contact"
                                   target="_blank">Contact us.</a>
                            </div>
                            <div class="kt-login__account">
                                <span class="kt-login__account-msg">
                                    Don't have an account yet?
                                </span>&nbsp;&nbsp;
                                <a :href="hostURL + '/member-sign-up/'" class="kt-login__account-link"
                                   id="kt_login_signup">Sign up!</a>
                            </div>
                        </div>
                    </div>

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid__item--center kt-grid kt-grid--ver kt-login__content"
                         style="background-image: url(/assets/media/bg/bg-4.jpg);">
                        <div class="kt-login__section" style="padding-left: 30px">
                            <div class="kt-login__block">
                                <h3 class="kt-login__title">Welcome to your Member Portal</h3>
                                <div class="kt-login__desc">
                                    Where you are in control of your coverage
                                    <br>and we'll help you along the way
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
    export default {
        name: "Login",
        props: {
            forgotPass: {
                type: Boolean,
                default: false
            }
        },
        mounted() {
            let username = this.$route.query.username;
            let token = this.$route.query.token;
            let route = this.$route.name;
            if (
                route.toLowerCase() === 'login' &&
                typeof username === 'string' &&
                typeof token === 'string'
            ) {
                this.email = username;
                this.token = token;
                this.tokenLogin();
            } else if (
                route.toLowerCase() === 'forgot password' &&
                typeof username === 'string'
            ) {
                this.email = username;

            }
        },
        data() {
            return {
                email: "",
                token: "",
                password: "",
                loginError: "",
                showLoginAlert: false,
                error: false,
                showForgotPasswordAlert: false,
                emailSent: false,
                lockedMessage: 'Your Account is locked. Please <u><a style="font-weight: 600" target="_blank" href="' + this.$hostname + '/contact-us/">contact us</a></u> at <u><a style="font-weight: 600" href="tel:877-448-6839">(877) 448-6839</a></u> or reset your password',
            }
        },
        computed: {
            hostURL: function () {
                return this.$hostname;
            },
        },
        methods: {
            login(e) {
                e.preventDefault();

                if (this.email.length > 0 && this.password.length > 0) {
                    this.axios.post('/auth/members/login', {
                        username: this.email,
                        password: this.password
                    })
                        .then(response => {
                            const decodedToken = this.$auth.login(response.data.access_token, response.data.expires_in);
                            if (!decodedToken) {
                                throw 'Unknown login error';
                            }

                            // Store the member's keys.
                            this.axios.defaults.headers.common['Authorization'] = 'bearer ' + this.$cookies.get('jwt');
                            localStorage.setItem('member:member_number', decodedToken.member_number);
                            localStorage.setItem('member:zuora_id', decodedToken.zuora_id);
                            this.$emit('loggedIn');

                        sessionStorage.setItem("reset_happened", false);

                        if (this.$route.params.nextUrl) {
                            this.$router.push(this.$route.params.nextUrl);
                        } else {
                            this.$router.push('/');
                        }
                    })
                    .catch(error => {
                        this.$cookies.remove('jwt');
                        if (error.response && error.response.status === 401) {

                                if (error.response.data.error === 'Unauthorized')
                                    this.loginError = "Your username or password is incorrect.";
                                else
                                    this.loginError = this.lockedMessage;

                            } else {
                                this.loginError = "Something went wrong. Please refresh and try again.";
                            }

                            this.showLoginAlert = this.loginError.length > 0;
                        });
                }
            },
            tokenLogin() {

                this.axios.post('/auth/members/loginToken', {
                    username: this.email,
                    token: this.token
                })
                    .then(response => {
                        const decodedToken = this.$auth.login(response.data.access_token, response.data.expires_in);
                        if (!decodedToken) {
                            throw 'Unknown login error';
                        }

                        this.token = '';
                        // Store the member's keys.
                        this.axios.defaults.headers.common['Authorization'] = 'bearer ' + this.$cookies.get('jwt');
                        localStorage.setItem('member:member_number', decodedToken.member_number);
                        localStorage.setItem('member:zuora_id', decodedToken.zuora_id);
                        this.$emit('loggedIn');

                        if (this.$route.params.nextUrl) {
                            this.$router.push(this.$route.params.nextUrl);
                        } else {
                            this.$router.push('/');
                        }
                    })
                    .catch(error => {
                        localStorage.removeItem('jwt');
                        let response = error.response;
                        this.token = '';
                        if (error.response && error.response.status === 401) {

                            if (response.data.error === 'Unauthorized')
                                this.loginError = "Login Unsuccessful. Please enter your login credentials to try again.";
                            else
                                this.loginError = this.lockedMessage;

                        } else {
                            this.loginError = "Something went wrong. Please refresh and try again.";
                        }

                        this.showLoginAlert = this.loginError.length > 0;
                    });

            },
            requestResetToken() {
                this.$http.post("/auth/members/tokens", {email: this.email}).then(() => {
                    this.emailSent = true;
                    this.error = false;
                }, () => {
                    this.error = true;
                });
            }
        }
    }
</script>
