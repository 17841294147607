<template>
    <section id="change_password">
        <div class="row">
            <div class="col-xl-12">
                <div class="kt-portlet kt-portlet--height-fluid">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">Change Password<small>change or reset your account
                                password</small></h3>
                        </div>
                    </div>
                    <validation-observer @submit.prevent="changePassword" class="kt-form kt-form--label-right"
                                         ref="observer"
                                         tag="form" v-slot="{ invalid }">
                        <div class="kt-portlet__body">
                            <div class="kt-section kt-section--first">
                                <div class="kt-section__body">
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label">New Password</label>
                                        <div class="col-lg-9 col-xl-6">
                                            <validation-provider mode="aggressive"
                                                                 name="password"
                                                                 rules="min:6"
                                                                 v-slot="{ errors }">
                                                <input class="form-control"
                                                       placeholder="New password"
                                                       type="password"
                                                       v-model="password">
                                                <span style='color:rgb(217,83,79);'>
                                                    {{ errors[0] }}
                                                </span>
                                            </validation-provider>
                                        </div>
                                    </div>
                                    <div class="form-group form-group-last row">
                                        <label class="col-xl-3 col-lg-3 col-form-label">Verify Password</label>
                                        <div class="col-lg-9 col-xl-6">
                                            <validation-provider mode="aggressive"
                                                                 name="confirmation"
                                                                 rules="required_if:password|confirm:password"
                                                                 v-slot="{ errors }">
                                                <input class="form-control"
                                                       placeholder="Verify password"
                                                       type="password"
                                                       v-model="verifyPass">
                                                <p style='color:rgb(217,83,79);'>
                                                    {{ errors[0] }}
                                                </p>
                                            </validation-provider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="kt-portlet__foot">
                            <div class="kt-form__actions">
                                <div class="row">
                                    <div class="col-lg-3 col-xl-3">
                                    </div>
                                    <div class="col-lg-9 col-xl-9">
                                        <alert :class="'col-lg-6'" :message="message"
                                               :show="showAlert" :type="alertType" v-on:close-alert="showAlert = false"/>
                                        <button :disabled="password === '' || verifyPass === '' || invalid"
                                                class="btn btn-brand btn-bold">Change Password
                                        </button>&nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </validation-observer>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import {extend} from 'vee-validate';
    import {min, required_if} from 'vee-validate/dist/rules';

    extend('required_if', {
        ...required_if,
        message: 'You must verify your new password.'
    });

    extend('min', {
        ...min,
        message: 'Your password must be at least 6 characters long.'
    });

    extend('confirm', {
        validate: (value, {other}) => value === other,
        message: 'Your passwords do not match.',
        params: [{name: 'other', isTarget: true}]
    });

    export default {
        props: {
            member: Object,
        },
        data() {
            return {
                showAlert: false,
                alertType: null,
                message: '',
                password: '',
                verifyPass: '',
                isValid: false,
            }
        },
        computed: {},
        methods: {
            changePassword() {
                this.axios.patch('/auth/members/' + this.member.zuora_id + '/password', {
                    password: this.password,
                    password_confirmation: this.verifyPass
                })
                    .then(response => {
                        this.showAlert = true;

                    sessionStorage.setItem("reset_happened", true);
                    const jwt_substr_save = this.$cookies.get('jwt').substr(0,190);
                    sessionStorage.setItem("reset_jwt", jwt_substr_save);

                    // Store the updated member info.
                    localStorage.setItem('member', JSON.stringify(this.member));

                        this.$auth.login(response.data.access_token, response.data.expires_in);
                        this.alertType = 'success';
                        this.message = 'Successfully updated password';
                        this.$emit('password-change-successful');
                    })
                    .catch(error => {
                        this.showAlert = true;
                        this.alertType = 'danger';
                        this.message = error.response.data.message;
                    });
            }
        }
    }
</script>

<style scoped>

</style>