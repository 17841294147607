<template>
    <section id="dashboard">
        <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">

            <!--Begin::Section-->
            <div class="row">
                <div class="col-lg-12">

                    <!--begin:: Active Profile -->
                    <div class="kt-portlet kt-portlet--height-fluid">
                        <div class="kt-portlet__body">
                            <div class="kt-widget kt-widget--user-profile-3">
                                <div class="kt-widget__top">
                                    <div class="kt-widget__media">
                                    </div>
                                    <div class="kt-widget__content">
                                        <div class="kt-widget__head">
                                            <a class="kt-widget__username" href="#">
                                                {{ member.firstName }} {{ member.lastName }}
                                                <i class="flaticon2-correct kt-font-brand"
                                                   v-show="activeSubscription"/>
                                            </a>
                                            <div class="kt-widget__action">
                                                <router-link class="btn btn-sm btn-upper" style="background: #edeff6"
                                                             to="/profile/personal-info">edit
                                                </router-link>
                                            </div>
                                        </div>
                                        <div class="kt-widget__subhead">
                                            <router-link title="Member Number" to="/profile"><i
                                                    class="flaticon2-calendar-3"/>{{ member.username }}
                                            </router-link>
                                            <router-link title="Email" to="/profile"><i class="flaticon2-new-email"></i>{{
                                                member.email.work || member.email.home}}
                                            </router-link>
                                            <router-link title="Location" to="/profile"><i
                                                    class="flaticon2-placeholder"/>{{ member.address.city }}, {{
                                                member.address.state }}
                                            </router-link>
                                        </div>

                                        <div class="kt-widget__info">
                                            <div class="kt-widget__stats">
                                                <div class="kt-widget__item" style="padding-top: 0;">
                                                    <table style="width:100%">
                                                        <tr>
                                                            <th class="kt-widget__date" style="margin-right: 10px; padding-top: 10px">
                                                                Start Date:
                                                            </th>
                                                            <th class="kt-widget__label">
                                                                <span class="btn btn-label-brand btn-sm btn-bold btn-upper">
                                                                    {{ formatDate(activeSubscription.termStartDate)}}
                                                                </span>
                                                            </th>
                                                            <th class="kt-widget__date" style="margin-right: 10px; margin-left: 20px;  padding-top: 10px">
                                                                End Date:
                                                            </th>
                                                            <th class="kt-widget__label">
                                                                <span class="btn btn-label-danger btn-sm btn-bold btn-upper">
                                                                    {{ formatDate(activeSubscription.termEndDate)}}
                                                                </span>
                                                            </th>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end:: Active Profile -->
                </div>
            </div>
            <!--End::Section-->
            <div class="row">
                <div class="col-md-6">
                    <!--begin:: Widgets/Events-->
                    <div class="kt-portlet kt-portlet--head--noborder kt-portlet--height-fluid">
                        <div class="kt-portlet__head">
                            <div class="kt-portlet__head-label">
							<span class="kt-portlet__head-icon">
								<i class="flaticon-event-calendar-symbol"/>
							</span>
                                <h3 class="kt-portlet__head-title">
                                    Events
                                </h3>
                            </div>
                        </div>
                        <div class="kt-portlet__body">
                            <div class="kt-widget15">
                                <div class="kt-widget15__items">
                                    <div class="row">
                                        <div class="col">
                                            <div class="kt-portlet--height-fluid" style="--aspect-ratio: 1/1;">
                                                <iframe :src="eventsURL  + '?st=' + member_state"
                                                        frameborder="0"
                                                        height="900"
                                                        width="1600"></iframe>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="kt-portlet kt-portlet--height-fluid kt-widget19">
                        <div class="kt-portlet__body kt-portlet__body--fit kt-portlet__body--unfill">
                            <div class="kt-widget19__pic kt-portlet-fit--top kt-portlet-fit--sides"
                                 style="min-height: 300px; background-image: url(https://assets.uslawshield.com/web/portal-education.jpg)">
                                <div class="kt-widget19__shadow"></div>
                            </div>
                        </div>
                        <div class="kt-portlet__body">
                            <div class="kt-widget19__wrapper">
                                <div class="kt-widget19__content">
                                    <h3>Legal Education Channel</h3>
                                </div>
                                <div class="kt-widget19__text">
                                    It is our passion to educate our members on firearms law. Only through eternal
                                    vigilance
                                    can our 2nd Amendment rights be ensured.
                                </div>
                            </div>
                            <div class="kt-widget19__action">
                                <router-link class="btn btn-sm btn-label-brand btn-bold"
                                             to="/education">Learn More...
                                </router-link>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-md-4" style="display:none;">
                    <div class="kt-portlet kt-portlet--height-fluid">
                        <div class="kt-portlet__head">
                            <div class="kt-portlet__head-label">
							<span class="kt-portlet__head-icon">
								<i class="flaticon-placeholder-3"></i>
							</span>
                                <h3 class="kt-portlet__head-title">
                                    Member Perks Near You
                                </h3>
                            </div>
                        </div>
                        <div class="kt-portlet__body">
                            <div id="kt_gmap_1" style="height:100%;"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import CheckAccountStatus from "./partials/CheckAccountStatus";

    export default {
        name: 'Dashboard',
        props: {
            member: Object,
            subscriptions: Array,
        },
        updated() {
            if (this.$route.query.eplan) {
                this.$router.push('/eplan/emergency-plan-form');
            }
        },
        computed: {
            member_state: function () {
                const states = [
                    {
                        "name": "Alabama",
                        "abbreviation": "AL"
                    },
                    {
                        "name": "Alaska",
                        "abbreviation": "AK"
                    },
                    {
                        "name": "American Samoa",
                        "abbreviation": "AS"
                    },
                    {
                        "name": "Arizona",
                        "abbreviation": "AZ"
                    },
                    {
                        "name": "Arkansas",
                        "abbreviation": "AR"
                    },
                    {
                        "name": "California",
                        "abbreviation": "CA"
                    },
                    {
                        "name": "Colorado",
                        "abbreviation": "CO"
                    },
                    {
                        "name": "Connecticut",
                        "abbreviation": "CT"
                    },
                    {
                        "name": "Delaware",
                        "abbreviation": "DE"
                    },
                    {
                        "name": "District Of Columbia",
                        "abbreviation": "DC"
                    },
                    {
                        "name": "Federated States Of Micronesia",
                        "abbreviation": "FM"
                    },
                    {
                        "name": "Florida",
                        "abbreviation": "FL"
                    },
                    {
                        "name": "Georgia",
                        "abbreviation": "GA"
                    },
                    {
                        "name": "Guam",
                        "abbreviation": "GU"
                    },
                    {
                        "name": "Hawaii",
                        "abbreviation": "HI"
                    },
                    {
                        "name": "Idaho",
                        "abbreviation": "ID"
                    },
                    {
                        "name": "Illinois",
                        "abbreviation": "IL"
                    },
                    {
                        "name": "Indiana",
                        "abbreviation": "IN"
                    },
                    {
                        "name": "Iowa",
                        "abbreviation": "IA"
                    },
                    {
                        "name": "Kansas",
                        "abbreviation": "KS"
                    },
                    {
                        "name": "Kentucky",
                        "abbreviation": "KY"
                    },
                    {
                        "name": "Louisiana",
                        "abbreviation": "LA"
                    },
                    {
                        "name": "Maine",
                        "abbreviation": "ME"
                    },
                    {
                        "name": "Marshall Islands",
                        "abbreviation": "MH"
                    },
                    {
                        "name": "Maryland",
                        "abbreviation": "MD"
                    },
                    {
                        "name": "Massachusetts",
                        "abbreviation": "MA"
                    },
                    {
                        "name": "Michigan",
                        "abbreviation": "MI"
                    },
                    {
                        "name": "Minnesota",
                        "abbreviation": "MN"
                    },
                    {
                        "name": "Mississippi",
                        "abbreviation": "MS"
                    },
                    {
                        "name": "Missouri",
                        "abbreviation": "MO"
                    },
                    {
                        "name": "Montana",
                        "abbreviation": "MT"
                    },
                    {
                        "name": "Nebraska",
                        "abbreviation": "NE"
                    },
                    {
                        "name": "Nevada",
                        "abbreviation": "NV"
                    },
                    {
                        "name": "New Hampshire",
                        "abbreviation": "NH"
                    },
                    {
                        "name": "New Jersey",
                        "abbreviation": "NJ"
                    },
                    {
                        "name": "New Mexico",
                        "abbreviation": "NM"
                    },
                    {
                        "name": "New York",
                        "abbreviation": "NY"
                    },
                    {
                        "name": "North Carolina",
                        "abbreviation": "NC"
                    },
                    {
                        "name": "North Dakota",
                        "abbreviation": "ND"
                    },
                    {
                        "name": "Northern Mariana Islands",
                        "abbreviation": "MP"
                    },
                    {
                        "name": "Ohio",
                        "abbreviation": "OH"
                    },
                    {
                        "name": "Oklahoma",
                        "abbreviation": "OK"
                    },
                    {
                        "name": "Oregon",
                        "abbreviation": "OR"
                    },
                    {
                        "name": "Palau",
                        "abbreviation": "PW"
                    },
                    {
                        "name": "Pennsylvania",
                        "abbreviation": "PA"
                    },
                    {
                        "name": "Puerto Rico",
                        "abbreviation": "PR"
                    },
                    {
                        "name": "Rhode Island",
                        "abbreviation": "RI"
                    },
                    {
                        "name": "South Carolina",
                        "abbreviation": "SC"
                    },
                    {
                        "name": "South Dakota",
                        "abbreviation": "SD"
                    },
                    {
                        "name": "Tennessee",
                        "abbreviation": "TN"
                    },
                    {
                        "name": "Texas",
                        "abbreviation": "TX"
                    },
                    {
                        "name": "Utah",
                        "abbreviation": "UT"
                    },
                    {
                        "name": "Vermont",
                        "abbreviation": "VT"
                    },
                    {
                        "name": "Virgin Islands",
                        "abbreviation": "VI"
                    },
                    {
                        "name": "Virginia",
                        "abbreviation": "VA"
                    },
                    {
                        "name": "Washington",
                        "abbreviation": "WA"
                    },
                    {
                        "name": "West Virginia",
                        "abbreviation": "WV"
                    },
                    {
                        "name": "Wisconsin",
                        "abbreviation": "WI"
                    },
                    {
                        "name": "Wyoming",
                        "abbreviation": "WY"
                    },
                    {
                        "name": "Washington DC",
                        "abbreviation": "DC"
                    }
                ];
                let state = states.find(a => a['name'] === this.member.address.state);
                return state['abbreviation'];
            },
            eventsURL: function () {
                return process.env.VUE_APP_EVENT_HOST;
            },
            activeSubscription: function () {
                this.checkAccountStatus();
                return this.subscriptions.find(element => element.status === 'Active') || this.subscriptions[0];
            },
            activeSubscriptions: function () {
                return this.subscriptions.filter(element => element.status === 'Active');
            },

        },
        methods: {
            formatDate: function (date) {
                date = new Date(date);
                date.setDate(date.getDate() + 1);
                date = date.toDateString().split(' ');
                date.shift();
                return date.join(' ');
            },
            checkAccountStatus: function () {
                if (this.member.status === 'Canceled') {
                    let _this = this;
                    this.$modal.show(
                        CheckAccountStatus,
                        {},
                        {
                            name: 'cancelled-account-modal',
                            adaptive: true,
                            height: 'auto',
                            width: '45%',
                            scrollable: true,
                            clickToClose: false,
                        },
                        {
                            'close-check': function () {
                                _this.$auth.logout();
                                _this.$emit('loggedOut');
                                window.open("https://www.uslawshield.com/contact-us/")
                            },
                        }
                    );
                }
            },
        }
    }
</script>