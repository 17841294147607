<template>
    <section id="education">
        <!-- begin:: Content -->
        <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div class="row">
                <div class="col-md-12">
                    <!--begin::Portlet-->
                    <div class="kt-portlet">
                        <div class="kt-portlet__head">
                            <div class="kt-portlet__head-label">
                                <h3 class="kt-portlet__head-title">Education</h3>
                            </div>
                        </div>
                        <div class="kt-portlet__body">
                            <!--begin::Section-->
                            <div class="kt-section">
                                <div class="kt-section__content">
                                    <div class="kt-portlet--height-fluid">
                                        <iframe :src="educationURL"
                                                id="iframe"
                                                style="min-width: 100%; margin-top: -20px; border: none; height: 1900px"></iframe>
                                    </div>
                                </div>

                            </div>
                            <!--end::Section-->
                        </div>
                    </div>
                    <!--end::Portlet-->
                </div>
            </div>
        </div>
        <!-- end:: Content -->
    </section>
</template>

<script>
    export default {
        name: 'LegacyEducation',
        computed: {
            educationURL: function () {
                const username = localStorage.getItem('member:member_number');
                const token = "mem_login_" + this.$cookies.get('jwt').substr(0,190);
                return this.$hostname + '/member-education-iframe.php?username=' + username + '&token=' + token + '&url=' + window.location.origin;
            }
        }
    }
</script>

<style>
    html, body {
        height: 100%;
    }
</style>
