import { render, staticRenderFns } from "./QuickActions.vue?vue&type=template&id=731b540e&scoped=true&"
import script from "./QuickActions.vue?vue&type=script&lang=js&"
export * from "./QuickActions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "731b540e",
  null
  
)

export default component.exports