<template>
    <div class="kt-header kt-grid__item kt-header--fixed " id="kt_header">
        <!-- begin: Header Menu -->
        <button class="kt-header-menu-wrapper-close" id="kt_header_menu_mobile_close_btn"><i
                class="la la-close"></i></button>
        <div class="kt-header-menu-wrapper" id="kt_header_menu_wrapper">
            <div class="kt-header-menu kt-header-menu-mobile kt-header-menu--layout-tab " id="kt_header_menu">
                <ul class="kt-menu__nav ">
                </ul>
            </div>
        </div>
        <!-- end: Header Menu -->
        <!-- begin:: Header Topbar -->
        <div class="kt-header__topbar">
            <div class="kt-header__topbar-item" v-show="showFacilityPortal">
                <router-link class="kt-header__topbar-wrapper header_tooltip" tag="div" to="/facility-portal">
                    <span class="kt-header__topbar-icon">
                        <i class="flaticon-network" style="font-size: 1.8rem"></i>
                    </span>
                    <span class="header_tooltip_text">Facility Portal</span>
                </router-link>
            </div>
            <div class="kt-header__topbar-item">
                <div @click="showQuickActionsMenu = !showQuickActionsMenu" class="kt-header__topbar-wrapper header_tooltip">
                    <span class="kt-header__topbar-icon">
                        <i class="flaticon2-gear"></i>
                    </span>
                    <span class="header_tooltip_text">Quick Menu</span>
                </div>
                <div @click="showQuickActionsMenu = false"
                     class="modal-mask"
                     v-show="showQuickActionsMenu">
                    <div
                            class="show dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl header-menus"
                            style="position: absolute; will-change: transform;"
                    >
                        <QuickActions :is_secondary="member.is_secondary"/>
                    </div>
                </div>
            </div>
            <!--end: Quick Actions -->
            <!--begin: Cart -->
            <!-- Not implemented....
            <div class="kt-header__topbar-item dropdown">
                <div class="kt-header__topbar-wrapper" data-offset="10px,0px" data-toggle="dropdown">
                    <span class="kt-header__topbar-icon"><i class="flaticon2-shopping-cart-1"></i><span
                            class="kt-badge kt-badge--danger">2</span></span>
                </div>
                <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-xl">
                    <form>
                        <MyCart/>
                    </form>
                </div>
            </div>
            -->
            <!--end: Cart-->
            <div class="kt-header__topbar-item kt-header__topbar-item--user">
                <div @click="showUserMenu = !showUserMenu" class="kt-header__topbar-wrapper">
                    <div class="kt-header__topbar-user">
                        <span class="kt-header__topbar-welcome">Hi,</span>
                        <span class="kt-header__topbar-username ">{{ firstName }}</span>
                    </div>
                </div>
                <div @click="showUserMenu = false"
                     class="modal-mask"
                     v-show="showUserMenu">
                    <div
                            class="show dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl header-menus"
                            style="position: absolute; will-change: transform;"
                    >
                        <UserMenu :member="member"/>
                    </div>
                </div>
            </div>
        </div>
        <!--end: User Bar -->
        <!-- end:: Header Topbar -->
    </div>
</template>

<script>
    import QuickActions from "./header_dropdown_menus/QuickActions";
    // import MyCart from "./header_dropdown_menus/MyCart";
    import UserMenu from "./header_dropdown_menus/UserMenu";

    export default {
        name: "Header",
        components: {
            QuickActions,
            // MyCart,
            UserMenu
        },
        props: {
            member: Object,
            showFacilityPortal: Boolean,
            toggleMobileUser: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                showQuickActionsMenu: false,
                showUserMenu: false,
            }
        },
        computed: {
            firstName: function () {
                if (!this.member || !this.member.firstName) {
                    return "Guest";
                }

                return this.member.firstName;
            }
        }
    }
</script>

<style scoped>

    .header-menus {
        top: 0px;
        left: 140px;
        transform: translate3d(calc(85vw - 380px), 79px, 0px);
    }

    @media only screen and (max-width: 1024px) {
        .header-menus {
            top: 0px;
            left: 0px;
        }
    }

    @media only screen and (max-width: 768px) {
        .header-menus {
            top: 0px;
            left: 0px;
            transform: translate3d(calc(85vw - 300px), 79px, 0px);
        }
    }

    @media only screen and (max-width: 425px) {
        .header-menus {
            top: 0px;
            left: 0px;
            transform: translate3d(calc((100vw - 300px) / 2), 79px, 0px);
        }
    }

    .header_tooltip .header_tooltip_text {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;

        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        top: 80%;
        margin-left: -40px;
    }

    .header_tooltip:hover .header_tooltip_text {
        visibility: visible;
    }

</style>
