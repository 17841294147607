<template>
    <section id="user_menu">
        <!--begin: Head -->
        <div class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
             style="background-image: url(assets/media/bg/bg-1.jpg)"
        >
            <div class="kt-user-card__name">{{ member.firstName }} {{ member.lastName }}</div>
        </div>
        <!--end: Head -->
        <!--begin: Navigation -->
        <div class="kt-notification">
            <router-link class="kt-notification__item" to="/profile">
                <div class="kt-notification__item-icon"><i class="flaticon2-calendar-3 kt-font-success"></i></div>
                <div class="kt-notification__item-details">
                    <div class="kt-notification__item-title kt-font-bold"> My Profile</div>
                    <div class="kt-notification__item-time"> Account settings and more</div>
                </div>
                <i class="la la-angle-right"></i>
            </router-link>
            <div class="kt-notification__custom kt-space-between">
                <a @click="logout" class="btn btn-label btn-label-brand btn-sm btn-bold">Sign Out</a>
            </div>
        </div>
        <!--end: Navigation -->
    </section>
</template>

<script>
    export default {
        name: "UserMenu",
        props: ['showUserMenu', 'member'],
        methods: {
            logout() {
                this.$auth.logout();

                this.$emit('loggedOut');
            }
        }
    }
</script>

<style scoped>

</style>
