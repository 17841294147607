import axios from 'axios';

import Vue from 'vue';
import VueAxios from 'vue-axios';
import VueRouter from 'vue-router';
import VueGtm from 'vue-gtm';
import VueCookies from 'vue-cookies';

import App from './App.vue';
import router from './router';
import SimpleJWTAuth from "../packages/simple-jwt-auth/src/SimpleJWTAuth";
import VModal from "vue-js-modal";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import Alert from "./components/partials/Alert";

Vue.prototype.$http = axios;
Vue.prototype.$auth = new SimpleJWTAuth(router, VueCookies);
Vue.prototype.$hostname = process.env.VUE_APP_USLS_HOST;
Vue.prototype.$tlsURL = process.env.VUE_APP_TLSAPI_HOST;

Vue.config.productionTip = false;
Vue.use(VueCookies);
Vue.use(VueAxios, axios);
Vue.use(VueRouter);
Vue.use(VueGtm,{
    id: process.env.VUE_APP_GOOGLE_TAG_KEY,
    enabled: true,
    loadScript: true,
    debug: false
});
Vue.use(VModal, {
    dynamic: true,
    injectModalsContainer: true,
});

//Custom Components
Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
Vue.component('alert', Alert);
Vue.component('vue-phone-number-input', VuePhoneNumberInput);

//Comment this line out in dev or set domain to null.
Vue.$cookies.config('7d','', 'uslawshield.com');

// // Setup the API client.
// Vue.http.options.root = process.env.VUE_APP_TLSAPI_HOST;
Vue.axios.defaults.baseURL = process.env.VUE_APP_TLSAPI_HOST;
(()=> {
    let token = Vue.$cookies.get('jwt');
    if (token) {
        axios.defaults.headers.common['Authorization'] = 'bearer ' + token;
    } else {
        delete axios.defaults.headers.common['Authorization'];
        /*if setting null does not remove `Authorization` header then try
          delete axios.defaults.headers.common['Authorization'];
        */
    }
})();
// Vue.axios.defaults.baseURL = 'http://localhost:8000';

new Vue({
    render: h => h(App),
    router
}).$mount('#app');
