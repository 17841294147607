<template>
    <div v-if="show" class="alert" :class="'alert-'+ alertType" role="alert">
        <div class="alert-text" v-html="message"></div>
        <button v-if="showClose" @click="close" aria-label="Close" class="close" type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</template>

<script>
    export default {
        name: "Alert",
        props: {
            message: String,
            type: String,
            show: {
                type: Boolean,
                default: false
            },
            showClose:{
                type: Boolean,
                default: true
            }
        },
        data(){
            return{
                alertTypes: [
                    'primary', 'secondary', 'success',
                    'danger', 'warning', 'info'
                ]
            }
        },
        computed: {
            alertType: function () {
                return this.alertTypes.includes(this.type) ? this.type : 'primary';
            }
        },
        methods: {
            close: function () {
                this.show = false;
                this.$emit('close-alert');
            }
        }

    }
</script>

<style scoped>

</style>