"use strict";

import jwtDecode from 'jwt-decode';

class SimpleJWTAuth {
    constructor(router, cookies) {
        this.router = router;
        this.cookies = cookies;
    }

    login(token, expiresIn) {
        this.cookies.set('jwt', token, (new Date).setSeconds(expiresIn));
        this.cookies.set('jwt-expires', (new Date).setSeconds(expiresIn));

        return jwtDecode(token);
    }

    isLoggedIn() {
        const jwtToken = localStorage.getItem('jwt');
        const jwtExpires = localStorage.getItem('jwt-expires');

        if (!jwtToken) {
            return false;
        }

        return jwtExpires >= (new Date()).getTime();
    }

    logout() {
        localStorage.clear();
        this.cookies.remove('jwt');
        this.cookies.remove('jwt-expires');
        this.router.push('/login').catch(() => {});
    }
}

export default SimpleJWTAuth;