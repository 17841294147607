"use strict";

export class CreditCardRepo {

    constructor(axios, zuora_id) {
        this.axios = axios;
        this.zuora_id = zuora_id;
        this.cards = null;
    }

    get() {
        return this.cards;
    }

    fetchInfo() {
        return new Promise((resolve, reject) => {
        this.axios.get('/zuora/members/' + this.zuora_id + '/creditCards')
            .then(response => {
                this.cards = response.data.creditCards;
                resolve(this.cards);
            })
            .catch(error => {
                reject(error);
            });
        });
    }
}
