<template>
    <section id="coverage">
        <!-- begin:: Content -->
        <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div class="row">
                <div class="col-md-12">
                    <!--begin::Portlet-->
                    <div class="kt-portlet">
                        <div class="kt-portlet__head">
                            <div class="kt-portlet__head-label">
                                <h3 class="kt-portlet__head-title">Facility Portal</h3>
                            </div>
                        </div>
                        <div class="kt-portlet__body">
                            <!--begin::Section-->
                            <div class="kt-section">
                                <div class="kt-section__content">
                                    <div class="kt-portlet--height-fluid">
                                        <iframe id="iframe"
                                                style="min-width: 100%; margin-top: -20px; border: none; height: 65vh"
                                                :src="coverageURL"></iframe>
                                    </div>
                                </div>

                            </div>
                            <!--end::Section-->
                        </div>
                    </div>
                    <!--end::Portlet-->
                </div>
            </div>
        </div>
        <!-- end:: Content -->
    </section>
</template>

<script>
    export default {
        name: 'LegacyFacilityPortal',
        computed: {
            coverageURL: function () {
                const username = localStorage.getItem('member:member_number');
                let token =  '';
                const reset_happened = sessionStorage.getItem("reset_happened");
                if (reset_happened === 'true' || reset_happened === true) {
                    let prevJWTsub = sessionStorage.getItem("reset_jwt");
                    token =  "mem_login_" + prevJWTsub;
                } else {
                    token =  "mem_login_" + this.$cookies.get('jwt').substr(0,190);
                }
                return this.$hostname + '/facility-dashboard-iframe.php?username=' + username + '&token=' + token;
            }
        }
    }
</script>

<style>
    html,body {
        height:100%;
    }
</style>
