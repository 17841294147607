<template>
    <!-- begin:: Footer -->
    <footer id="footer">
        <div class="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop" id="kt_footer">
            <div class="kt-container kt-container--fluid ">
                <div class="kt-footer__copyright">
                    <div class="kt-footer__menu">
                        <a :href="hostURL + '/about-us-2/'" class="kt-footer__menu-link kt-link"
                           target="_blank">About</a>
                        <a :href="hostURL + '/contact-us/'" class="kt-footer__menu-link kt-link"
                           target="_blank">Contact</a>
                        <a :href="hostURL + '/terms-and-conditions/'" class="kt-footer__menu-link kt-link"
                           target="_blank">Terms & Conditions</a>
                        <a :href="hostURL + '/privacy-policy/'" class="kt-footer__menu-link kt-link"
                           target="_blank">Privacy Policy</a>
                        <p class="copyright">&nbsp;&copy;&nbsp; {{ now }} U.S. & Texas LawShield</p>
                    </div>
                </div>

            </div>
        </div>
    </footer>
    <!-- end:: Footer -->
</template>

<script>
    export default {
        name: 'Footer',
        data() {
            return {
                now: new Date().getFullYear()
            }
        },
        computed: {
            hostURL: function () {
                return this.$hostname;
            },
        }

    }
</script>

<style scoped>
    .copyright{
        margin: 0 0 0 2rem;
        padding: 0;
        font-size: 1rem;
        font-weight: 200;
        color: #595d6e;
    }
</style>