<template>
    <section id="edit_billing">

        <!-- begin:: Content Head -->
        <div class="kt-subheader  kt-grid__item" id="kt_subheader">
            <div class="kt-container  kt-container--fluid ">
                <div class="kt-subheader__main">
                    <button @click="toggleMobileMenu = true" class="kt-subheader__mobile-toggle"
                            id="kt_subheader_mobile_toggle"><span></span></button>
                </div>
                <div class="kt-subheader__toolbar">
                </div>
            </div>
        </div>
        <!-- end:: Content Head -->

        <!-- begin:: Content -->
        <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

            <!--Begin::App-->
            <div class="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">

                <!--Begin:: App Aside-->
                <div
                        :class="{'kt-app__aside--on': toggleMobileMenu}"
                        class="kt-grid__item kt-app__toggle kt-app__aside"
                        id="kt_user_profile_aside"
                >
                    <!--Begin:: App Aside Mobile Toggle-->
                    <button @click="toggleMobileMenu = false" class="kt-app__aside-close"
                            id="kt_user_profile_aside_close">
                        <i class="la la-close"/>
                    </button>
                    <!--End:: App Aside Mobile Toggle-->

                    <!--begin:: Widgets/Applications/User/Profile1-->
                    <div class="kt-portlet ">
                        <div class="kt-portlet__body kt-portlet__body--fit-y">
                            <!--begin::Widget -->
                            <div class="kt-widget kt-widget--user-profile-1" style="height: 500px" v-if="!member"></div>
                            <div class="kt-widget kt-widget--user-profile-1" v-if="member">
                                <div class="kt-widget__head">
                                    <div class="kt-widget__content">
                                        <div class="kt-widget__section">
                                            <a class="kt-widget__username" href="#">
                                                <!-- ZuoraId: -->
                                                {{ member.firstName }} {{ member.lastName}}
                                                <i class="flaticon2-correct kt-font-success"><span
                                                        style="display: none">{{ member.zuora_id }}</span></i>
                                            </a>
                                            <span class="kt-widget__subtitle">
                                                {{ member.status }}
                                            </span>
                                        </div>

                                        <div class="kt-widget__action" v-if="member.status !== 'Active'">
                                            <button class="btn btn-success btn-sm" type="button">Activate</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="kt-widget__body">
                                    <div class="kt-widget__content">
                                        <div class="kt-widget__info">
                                            <span class="kt-widget__label">Email:</span>
                                            {{ member.email.work || member.email.home }}
                                        </div>
                                        <div class="kt-widget__info">
                                            <span class="kt-widget__label">Phone:</span>
                                            {{ member.phone.home || member.phone.cell || member.phone.work }}
                                        </div>
                                        <div class="kt-widget__info">
                                            <span class="kt-widget__label">Location:</span>
                                            <span class="kt-widget__data">{{ location }}</span>
                                        </div>
                                        <div class="kt-widget__info">
                                            <span class="kt-widget__label">Member #:</span>
                                            {{ member.member_number }}
                                        </div>
                                    </div>
                                    <div class="kt-widget__items">
                                        <router-link class="kt-widget__item"
                                                     exact-active-class="kt-widget__item--active" id="invoices_link"
                                                     to="/billing/invoices"
                                                     v-show="!member.is_secondary">
                                            <span class="kt-widget__section">
                                                <span class="kt-widget__icon">
                                                    <svg class="kt-svg-icon" height="24px" version="1.1"
                                                         viewBox="0 0 24 24" width="24px"
                                                         xmlns="http://www.w3.org/2000/svg"
                                                         xmlns:xlink="http://www.w3.org/1999/xlink">
                                                        <g fill="none" fill-rule="evenodd" stroke="none"
                                                           stroke-width="1">
                                                            <rect height="24" id="bound" width="24" x="0" y="0"/>
                                                            <rect fill="#000000" height="3" id="Rectangle-20" rx="1.5"
                                                                  width="16" x="4" y="5"/>
                                                            <path d="M5.5,15 L18.5,15 C19.3284271,15 20,15.6715729 20,16.5 C20,17.3284271 19.3284271,18 18.5,18 L5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 Z M5.5,10 L12.5,10 C13.3284271,10 14,10.6715729 14,11.5 C14,12.3284271 13.3284271,13 12.5,13 L5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 Z"
                                                                  fill="#000000" id="Combined-Shape" opacity="0.3"/>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <span class="kt-widget__desc">
                                                    Invoices
                                                </span>
                                            </span>
                                        </router-link>
                                        <router-link class="kt-widget__item"
                                                     exact-active-class="kt-widget__item--active" id="billing_link"
                                                     to="/billing/billing-account"
                                                     v-show="!member.is_secondary">
                                            <span class="kt-widget__section">
                                                <span class="kt-widget__icon">
                                                    <svg class="kt-svg-icon" height="24px"
                                                         version="1.1" viewBox="0 0 24 24" width="24px"
                                                         xmlns="http://www.w3.org/2000/svg"
                                                         xmlns:xlink="http://www.w3.org/1999/xlink">
                                                            <g fill="none"
                                                               fill-rule="evenodd"
                                                               id="Stockholm-icons-/-Layout-/-Layout-top-panel-5"
                                                               stroke="none"
                                                               stroke-width="1">
                                                                <rect height="24" id="bound" width="24" x="0"
                                                                      y="0"></rect>
                                                                <path d="M3,4 L20,4 C20.5522847,4 21,4.44771525 21,5 L21,7 C21,7.55228475 20.5522847,8 20,8 L3,8 C2.44771525,8 2,7.55228475 2,7 L2,5 C2,4.44771525 2.44771525,4 3,4 Z M10,10 L20,10 C20.5522847,10 21,10.4477153 21,11 L21,13 C21,13.5522847 20.5522847,14 20,14 L10,14 C9.44771525,14 9,13.5522847 9,13 L9,11 C9,10.4477153 9.44771525,10 10,10 Z M10,16 L20,16 C20.5522847,16 21,16.4477153 21,17 L21,19 C21,19.5522847 20.5522847,20 20,20 L10,20 C9.44771525,20 9,19.5522847 9,19 L9,17 C9,16.4477153 9.44771525,16 10,16 Z"
                                                                      fill="#000000" id="Combined-Shape"></path>
                                                                <rect fill="#000000" height="10" id="Rectangle-7-Copy-2"
                                                                      opacity="0.3" rx="1" width="5" x="2"
                                                                      y="10"></rect>
                                                            </g>
                                                    </svg>
                                                </span>
                                                <span class="kt-widget__desc">
                                                    Billing Account
                                                </span>
                                            </span>
                                        </router-link>
                                        <router-link class="kt-widget__item"
                                                     exact-active-class="kt-widget__item--active" id="make_payment_link"
                                                     to="/billing/make-payment"
                                                     v-show="!member.is_secondary">
                                            <span class="kt-widget__section">
                                                <span class="kt-widget__icon">
                                                    <svg class="kt-svg-icon" height="24px"
                                                         version="1.1" viewBox="0 0 24 24" width="24px"
                                                         xmlns="http://www.w3.org/2000/svg"
                                                         xmlns:xlink="http://www.w3.org/1999/xlink">
                                                        <g fill="none" fill-rule="evenodd" stroke="none"
                                                           stroke-width="1">
                                                            <rect height="24" id="bound" width="24" x="0" y="0"/>
                                                            <rect fill="#000000" height="4" id="Rectangle-7-Copy" rx="1"
                                                                  width="19" x="2" y="5"/>
                                                            <rect fill="#000000" height="10" id="Rectangle-7-Copy-4"
                                                                  opacity="0.3" rx="1" width="19" x="2" y="11"/>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <span class="kt-widget__desc">
                                                    Make Payment
                                                </span>
                                            </span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <!--end::Widget -->
                        </div>
                    </div>
                    <!--end:: Widgets/Applications/User/Profile1-->

                </div>
                <div @click="toggleMobileMenu = false" class="kt-app__aside-overlay" v-show="toggleMobileMenu"></div>
                <!--End:: App Aside-->

                <!--Begin:: App Content-->
                <div class="kt-grid__item kt-grid__item--fluid kt-app__content" style="margin-top: -20px">.
                    <keep-alive>
                        <router-view
                                :member="member"
                        ></router-view>
                    </keep-alive>
                </div>
                <!--End:: App Content-->
            </div>
            <!--End::App-->
        </div>
        <!-- end:: Content -->
    </section>
</template>

<script>
    export default {
        props: {
            member: Object,
        },
        beforeRouteEnter(to, from, next) {
            let member = JSON.parse(localStorage.getItem('member'));
            if (member.is_secondary) {
                next('/');
            } else {
                next();
            }
        },
        data() {
            return {
                toggleMobileMenu: false
            }
        },
        computed: {
            location: function () {
                return this.member.address.city + ', ' + this.member.address.state;
            }
        }
    }
</script>
