<template>
    <section id="emergency_plan">
        <!-- begin:: Content -->
        <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div class="row">
                <div class="col-md-12">
                    <!--begin::Portlet-->
                    <div class="kt-portlet">
                        <div class="kt-portlet__head">
                            <div class="kt-portlet__head-label">
                                <h3 class="kt-portlet__head-title">24/7/365 Emergency Access</h3>
                            </div>
                        </div>
                        <div class="kt-portlet__body">
                            <!--begin::Section-->
                            <div class="kt-section">
                                <div class="kt-section__content">
                                    <h4>Our 24/7/365 attorney answered firearms hotline is available only to the members
                                        of the U.S. LawShield Firearms Program <small class="text-muted"> – when you
                                            call the hotline, you are talking to a Independent Program Attorney.</small>
                                    </h4>
                                    <p>If you have a “use” of a firearm incident or have been arrested and charged with
                                        any crime involving a firearm or other lawful weapon, it is critical that you
                                        contact your firearms legal defense Independent Program Attorney without delay.
                                        Legal advice is only a phone call away: day, night, weekend or holidays. Under
                                        the U.S. LawShield Firearms Program, we have already arranged for you to have a
                                        Independent Program Attorney. When you call, you are speaking to an actual
                                        firearms program attorney, who is there to help.</p>
                                </div>
                            </div>
                            <!--end::Section-->
                        </div>
                    </div>
                    <!--end::Portlet-->
                </div>
            </div>
        </div>
        <!-- end:: Content -->
    </section>

</template>

<script>
    export default {
        name: "EmergencyAccess"
    }
</script>

<style scoped>

</style>