<template>
    <section id="what-to-do">
        <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div class="kt-wizard-v4" data-ktwizard-state="step-first" id="kt_wizard_v4">
                <!--begin: Form Wizard Nav -->
                <div class="kt-wizard-v4__nav">
                    <div class="kt-wizard-v4__nav-items">
                        <!--doc: Replace A tag with SPAN tag to disable the step link click -->
                        <div :data-ktwizard-state="state(tabs['account'])" @click="tab = tabs['account']"
                             class="kt-wizard-v4__nav-item" data-ktwizard-type="step">
                            <div class="kt-wizard-v4__nav-body">
                                <div class="kt-wizard-v4__nav-number">
                                    1
                                </div>
                                <div class="kt-wizard-v4__nav-label">
                                    <div class="kt-wizard-v4__nav-label-title eplan_tab">
                                        Account
                                    </div>
                                    <div class="kt-wizard-v4__nav-label-desc">
                                        Contact Information
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div :data-ktwizard-state="state(tabs['notification'])" @click="tab = tabs['notification']"
                             class="kt-wizard-v4__nav-item" data-ktwizard-type="step">
                            <div class="kt-wizard-v4__nav-body">
                                <div class="kt-wizard-v4__nav-number">
                                    2
                                </div>
                                <div class="kt-wizard-v4__nav-label">
                                    <div class="kt-wizard-v4__nav-label-title eplan_tab">
                                        Notification
                                    </div>
                                    <div class="kt-wizard-v4__nav-label-desc">
                                        Emergency Contacts
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div :data-ktwizard-state="state(tabs['legal_defense'])" @click="tab = tabs['legal_defense']"
                             class="kt-wizard-v4__nav-item" data-ktwizard-type="step">
                            <div class="kt-wizard-v4__nav-body">
                                <div class="kt-wizard-v4__nav-number">
                                    3
                                </div>
                                <div class="kt-wizard-v4__nav-label">
                                    <div class="kt-wizard-v4__nav-label-title eplan_tab">
                                        Legal Defense
                                    </div>
                                    <div class="kt-wizard-v4__nav-label-desc">
                                        Special Instructions
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end: Form Wizard Nav -->
                <div class="kt-portlet">
                    <div class="kt-portlet__body kt-portlet__body--fit">
                        <div class="kt-grid">
                            <div class="kt-grid__item kt-grid__item--fluid kt-wizard-v4__wrapper">
                                <!--begin: Form Wizard Form-->
                                <form @submit.prevent="submit" class="kt-form" id="kt_form">
                                    <!--begin: Form Wizard Step 1-->
                                    <div :data-ktwizard-state="state(tabs['account'])" class="kt-wizard-v4__content"
                                         data-ktwizard-type="step-content">
                                        <div class="kt-heading kt-heading--md">Enter Your Account Details</div>
                                        <p class="eplan_text">Please enter the information for your emergency plan
                                            below. After entering
                                            the basic information, you will be prompted to a second secure step where
                                            you can provide personal instructions to a Independent Program Attorney to
                                            assist with your legal representation in the event you experience a covered
                                            incident.</p>
                                        <div class="kt-form__section kt-form__section--first">
                                            <div class="kt-wizard-v4__form">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label>First Name</label>
                                                            <input class="form-control" name="fname"
                                                                   placeholder="First Name"
                                                                   type="text" v-model="eplan.firstName">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label>Last Name</label>
                                                            <input class="form-control" name="lname"
                                                                   placeholder="Last Name"
                                                                   type="text" v-model="eplan.lastName">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label>Member Number</label>
                                                            <input class="form-control" disabled
                                                                   name="membernumber"
                                                                   placeholder="Member Number" type="text"
                                                                   v-model="eplan.member_number">
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5>Address Details</h5>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <input class="form-control" name="address1"
                                                                   placeholder="Address Line 1"
                                                                   type="text" v-model="eplan.address1">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <input class="form-control" name="address2"
                                                                   placeholder="Address Line 2"
                                                                   type="text" v-model="eplan.address2">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <input class="form-control" name="state" placeholder="City"
                                                                   type="text" v-model="eplan.city">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <select class="form-control kt-selectpicker"
                                                                    id="kt_select2_1" name="param"
                                                                    v-model="eplan.state">
                                                                <option :key="state" :value="state"
                                                                        v-for="state in states">{{ state
                                                                    }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <input class="form-control" maxlength="5"
                                                                   name="postcode"
                                                                   placeholder="Postcode"
                                                                   type="text" v-model="eplan.zip">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label for="kt_inputmask_1">Member Date of Birth</label>
                                                            <input :max="maxDate" class="form-control"
                                                                   id="kt_inputmask_1" min="1900-01-01"
                                                                   type="date" v-model="eplan.dob"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div class="form-group">
                                                            <label></label>
                                                            <span class="form-text text-muted eplan_text">In the unfortunate event that you are ever in police custody, many facilities track persons by name AND birth date for purposes of positive identification. This field is optional, but highly recommended.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end: Form Wizard Step 1-->

                                    <!--begin: Form Wizard Step 2-->
                                    <div :data-ktwizard-state="state(tabs['notification'])"
                                         class="kt-wizard-v4__content"
                                         data-ktwizard-type="step-content">
                                        <div class="kt-form__section kt-form__section--first">
                                            <div class="kt-heading kt-heading--md">In case of legal emergency, please
                                                notify:
                                            </div>
                                            <div :key="i" v-for="(label, i) in eContactLabels">
                                                <h6>{{ label }}</h6>
                                                <div class="kt-separator kt-separator--space-sm kt-separator--border-solid"></div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label>Name<span v-if="i===0">*</span></label>
                                                            <input class="form-control" name="fname"
                                                                   placeholder="First/Last"
                                                                   type="text" v-model="eplan.contacts[i].name">
                                                            <span class="form-text text-muted eplan_text">Please enter the first & last name.</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label>Relationship<span v-if="i===0">*</span></label>
                                                            <input class="form-control" name="relationshipname"
                                                                   placeholder="Mom/Dad/Guardian/Brother/Sister"
                                                                   type="text" v-model="eplan.contacts[i].relationship">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <label>Phone<span v-if="i===0">*</span></label>
                                                            <input class="form-control" name="phone1"
                                                                   placeholder="phone"
                                                                   type="tel" v-model="eplan.contacts[i].phone">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <label>Alt Phone</label>
                                                            <input class="form-control" name="phone2"
                                                                   placeholder="phone"
                                                                   type="tel" v-model="eplan.contacts[i].altPhone">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end: Form Wizard Step 2-->

                                    <!--begin: Form Wizard Step 3-->
                                    <div :data-ktwizard-state="state(tabs['legal_defense'])"
                                         class="kt-wizard-v4__content"
                                         data-ktwizard-type="step-content">
                                        <div class="kt-form__section kt-form__section--first">
                                            <div class="kt-heading kt-heading--md">Special Instructions
                                            </div>
                                            <p class="eplan_terms">To My Future Independent Program Attorney</p>
                                            <p class="eplan_terms">As a Member of the U.S. & Texas LawShield Program, I
                                                am providing the information below to the Independent Program Attorneys
                                                representing me after a covered legal incident. My special instructions
                                                are intended to be confidential and protected by the attorney-client
                                                privilege and work product doctrine under the rules, statutes, and
                                                common law of any applicable jurisdiction, state and federal.</p>
                                            <p class="eplan_terms">
                                                My emergency contact(s) is provided so that they can act as my
                                                representative if I am unavailable to speak to the Independent Program
                                                Attorney assigned to my case. I authorize my future Independent Program
                                                Attorney to contact my emergency contact(s) to discuss or obtain any
                                                information necessary to assist in my representation.
                                            </p>
                                            <p class="eplan_terms"><strong>Your special instructions should be specific
                                                and should not
                                                include any
                                                information that you do not want shared with your emergency contact.
                                                Your emergency plan is transmitted directly to a repository only
                                                accessible by an Independent Program Attorney. No copies are
                                                kept.</strong></p>
                                            <div class="kt-space-20"></div>
                                            <div class="kt-wizard-v3__review-item">
                                                <div class="form-group">
                                                    <label for="exampleTextarea">Special Instructions</label>
                                                    <textarea class="form-control" id="exampleTextarea"
                                                              rows="3" v-model="eplan.special_instructions"></textarea>
                                                </div>
                                                <div class="form-group">
                                                    <label class="eplan_text">Choose One*</label>
                                                    <div class="kt-radio-list">
                                                        <label class="kt-radio">
                                                            <input name="radio1" type="radio"
                                                                   v-model="eplan.choose_one"
                                                                   value="I have provided special instructions in the above provided area"
                                                            >
                                                            I have provided special instructions in the above provided
                                                            area
                                                            <span></span>
                                                        </label>
                                                        <label class="kt-radio">
                                                            <input name="radio2" type="radio" v-model="eplan.choose_one"
                                                                   value="I am choosing to submit my emergency plan without providing special instructions"
                                                            >
                                                            I am choosing to submit my emergency plan without providing
                                                            special instructions
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <p class="eplan_text">I have no current legal issues, matters, claims or
                                                    potential claims for which I am seeking representation by the
                                                    transmission of this emergency plan. This information is to be used
                                                    only if I ever have a covered incident under the terms of my Member
                                                    legal services contract or policy.</p>
                                            </div>
                                            <div class="kt-space-20"></div>
                                        </div>
                                    </div>
                                    <!--end: Form Wizard Step 3-->
                                    <alert :message="message" :show="showAlert && message !== ''"
                                           :type="alertType" v-on:close-alert="showAlert = false"/>
                                    <!--begin: Form Actions -->
                                    <div class="kt-form__actions">
                                        <button :style="{ display:
                                                tab === tabs['notification'] || tab === tabs['legal_defense'] ?
                                                 'inline-block' :
                                                 'none'
                                                }"
                                                @click.prevent="prev"
                                                class="btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                                                data-ktwizard-type="action-prev">
                                            Previous
                                        </button>
                                        <button :disabled="alertType === 'success'"
                                                :style="{ display:
                                                tab === tabs['legal_defense'] ?
                                                 'inline-block' :
                                                 'none'
                                                }"
                                                class="btn btn-success btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                                                data-ktwizard-type="action-submit">
                                            Submit
                                        </button>
                                        <button :style="{ display:
                                                tab === tabs['notification'] || tab === tabs['account'] ?
                                                 'inline-block' :
                                                 'none'
                                                }"
                                                @click.prevent="next"
                                                class="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                                                data-ktwizard-type="action-next">
                                            Next Step
                                        </button>
                                    </div>
                                    <!--end: Form Actions -->
                                </form>
                                <!--end: Form Wizard Form-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

    import moment from 'moment';

    export default {
        name: "EmergencyPlan",
        props: {
            member: Object
        },
        data() {
            return {
                showAlert: false,
                alertType: null,
                formErrors: null,
                message: '',
                tab: 1,
                tabs: {
                    'account': 1,
                    'notification': 2,
                    'legal_defense': 3
                },
                eContactLabels: [
                    'First Contact',
                    'Second Contact',
                    'Third Contact'
                ],
                eplan: {
                    firstName: this.member.firstName,
                    lastName: this.member.lastName,
                    member_number: this.member.member_number,
                    address1: this.member.address.address1,
                    address2: this.member.address.address2,
                    city: this.member.address.city,
                    state: this.member.address.state,
                    zip: this.member.address.zipcode,
                    dob: null,
                    contacts: [
                        {
                            name: '',
                            relationship: '',
                            phone: '',
                            altPhone: ''
                        },
                        {
                            name: '',
                            relationship: '',
                            phone: '',
                            altPhone: ''
                        },
                        {
                            name: '',
                            relationship: '',
                            phone: '',
                            altPhone: ''
                        }
                    ],
                    special_instructions: '',
                    choose_one: ''
                },
                states: [
                    "Alaska",
                    "Hawaii",
                    "California",
                    "Nevada",
                    "Oregon",
                    "Washington",
                    "Arizona",
                    "Colorado",
                    "Idaho",
                    "Montana",
                    "Nebraska",
                    "New Mexico",
                    "North Dakota",
                    "Utah",
                    "Wyoming",
                    "Alabama",
                    "Arkansas",
                    "Illinois",
                    "Iowa",
                    "Kansas",
                    "Kentucky",
                    "Louisiana",
                    "Minnesota",
                    "Mississippi",
                    "Missouri",
                    "Oklahoma",
                    "South Dakota",
                    "Texas",
                    "Tennessee",
                    "Wisconsin",
                    "Connecticut",
                    "Delaware",
                    "Florida",
                    "Georgia",
                    "Indiana",
                    "Maine",
                    "Maryland",
                    "Massachusetts",
                    "Michigan",
                    "New Hampshire",
                    "New Jersey",
                    "New York",
                    "North Carolina",
                    "Ohio",
                    "Pennsylvania",
                    "Rhode Island",
                    "South Carolina",
                    "Vermont",
                    "Virginia",
                    "West Virginia"
                ]
            }
        },
        computed: {
            maxDate: function () {
                let date = new Date();
                return date.toISOString().slice(0, -14);
            }
        },
        methods: {
            formatEPlanData() {
                // Creates a Deep Copy of this.eplan
                let eplan = JSON.parse(JSON.stringify(this.eplan));

                eplan.member_name = eplan.firstName + " " + eplan.lastName;
                delete eplan.firstName;
                delete eplan.lastName;
                if (eplan.dob === null)
                    delete eplan.dob;
                else {
                    let date = moment(eplan.dob);
                    eplan.dob = date.format('L');
                }
                Object.keys(eplan.contacts).forEach(function (index) {
                    let contact = eplan.contacts[index];
                    index++;
                    eplan['name' + index] = contact.name;
                    eplan['relationship' + index] = contact.relationship;
                    eplan['phone' + index] = contact.phone;
                    eplan['alt_phone' + index] = contact.altPhone;
                });
                delete eplan.contacts;

                Object.keys(eplan).forEach(function (index) {
                    if (eplan[index] === "") {
                        delete eplan[index];
                    }
                });

                return eplan;
            },
            submit: function () {
                let eplan = this.formatEPlanData();
                this.showAlert = true;
                this.axios.post('/usls/members/' + this.member.zuora_id + '/eplan', eplan)
                    .then(response => {
                        const data = response.data;
                        if (typeof data == "string") {
                            this.alertType = 'success';
                            this.message = data;
                        } else {
                            this.alertType = 'danger';
                            this.message = 'There are errors in the following fields: ' + this.formatFormErrors(data).join(', ') + '.';
                        }
                    })
                    .catch(error => {
                        this.alertType = 'danger';
                        this.message = 'Something went wrong. Please try again later. If the problem persists contact support.';
                        console.log(error);
                    });
            },
            prev: function () {
                switch (this.tab) {
                    case this.tabs['notification']:
                        this.tab = this.tabs['account'];
                        break;
                    case this.tabs['legal_defense']:
                        this.tab = this.tabs['notification'];
                        break;
                    default:
                        this.tab = this.tabs['account'];
                }

            },
            next: function () {
                switch (this.tab) {
                    case this.tabs['account']:
                        this.tab = this.tabs['notification'];
                        break;
                    case this.tabs['notification']:
                        this.tab = this.tabs['legal_defense'];
                        break;
                    default:
                        this.tab = this.tabs['account'];
                }
            },
            state: function (tab) {
                return this.tab === tab ? 'current' : 'pending';
            },
            formatFormErrors(errors) {
                let error = [];
                for (const field in errors) {
                    switch (field) {
                        case 'dob':
                            error.push('Member Date of Birth');
                            break;
                        case 'name1':
                            error.push('First Contact Name');
                            break;
                        case 'name2':
                            error.push('Second Contact Name');
                            break;
                        case 'name3':
                            error.push('Third Contact Name');
                            break;
                        case 'relationship1':
                            error.push('First Contact Relationship');
                            break;
                        case 'relationship2':
                            error.push('Second Contact Relationship');
                            break;
                        case 'relationship3':
                            error.push('Third Contact Relationship');
                            break;
                        case 'phone1':
                            error.push('First Contact Phone');
                            break;
                        case 'phone2':
                            error.push('Second Contact Phone');
                            break;
                        case 'phone3':
                            error.push('Third Contact Phone');
                            break;
                        default:
                            error.push(
                                field.split('_')
                                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                                    .join(' ')
                            );
                            break;
                    }
                }
                return error;
            }
        }
    }
</script>

<style scoped>

    .eplan_tab {
        font-size: 1.5rem;
    }

    .eplan_text {
        font-size: 14px;
    }

    .eplan_terms {
        font-size: 16px;
    }

</style>
