<template>
    <section id="sidemenu">
        <button @click="close" class="kt-aside-close " id="kt_aside_close_btn"><i class="la la-close"></i></button>
        <div :class="{'kt-aside--on' : toggleMobileAside, 'scroll_normal': !showFacilityPortal, 'scroll_facility': showFacilityPortal}"
             class="kt-aside kt-aside--fixed kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop"
             id="kt_aside">
            <!-- begin:: Aside -->
            <div class="kt-aside__brand kt-grid__item " id="kt_aside_brand">
                <div class="kt-aside__brand-logo">
                    <router-link to="/">
                        <img alt="Logo" src="/assets/media/logos/logo-4.png"/>
                    </router-link>
                </div>
            </div>
            <!-- begin:: Aside Menu -->
            <div class="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
                <div class="kt-aside-menu kt-aside-menu--dropdown " data-ktmenu-dropdown="1" data-ktmenu-scroll="0"
                     data-ktmenu-vertical="1" id="kt_aside_menu">
                    <ul class="kt-menu__nav ">
                        <router-link aria-haspopup="true" class="kt-menu__item" exact
                                     exact-active-class="kt-menu__item--active" tag="li"
                                     to="/">
                            <a class="kt-menu__link">
                                <i class="kt-menu__link-icon flaticon-home-2"></i>
                                <span class="kt-menu__link-text">Dashboard</span>
                            </a>
                        </router-link>
                        <router-link :class="{'kt-menu__item--active': this.$route.fullPath.includes('profile')}"
                                     aria-haspopup="true" class="kt-menu__item"
                                     exact tag="li"
                                     to="/profile">
                            <a class="kt-menu__link">
                                <i class="kt-menu__link-icon flaticon-profile-1"></i>
                                <span class="kt-menu__link-text">Profile</span>
                            </a>
                        </router-link>
                        <router-link :class="{'kt-menu__item--active': this.$route.fullPath.includes('billing')}"
                                     aria-haspopup="true" class="kt-menu__item"
                                     exact tag="li"
                                     to="/billing"
                                     v-if="!is_secondary"
                                     v-show="!is_secondary">
                            <a class="kt-menu__link">
                                <i class="kt-menu__link-icon flaticon2-checking"></i>
                                <span class="kt-menu__link-text">Billing</span>
                            </a>
                        </router-link>
                        <li :class="{
                                'kt-menu__item--hover' : eplan,
                                'kt-menu__item--open' : eplan,
                                'kt-menu__item--active': this.$route.fullPath.includes('eplan')
                                }"
                            @mouseleave="eplan = false"
                            @mouseover="eplan = true" aria-haspopup="true"
                            class="kt-menu__item kt-menu__item--submenu"
                        >
                            <a class="kt-menu__link kt-menu__toggle"><i
                                    class="kt-menu__link-icon flaticon2-sheet"></i><span class="kt-menu__link-text">E-Plan</span>
                            </a>
                            <div class="kt-menu__submenu scrolled-submenu">
                                <span class="kt-menu__arrow"></span>
                                <ul class="kt-menu__subnav">
                                    <li aria-haspopup="true" class="kt-menu__item kt-menu__item--parent">
                                        <span class="kt-menu__link"><span
                                                class="kt-menu__link-text">E-Plan</span></span>
                                    </li>
                                    <router-link aria-haspopup="true" class="kt-menu__item" tag="li"
                                                 to="/eplan/emergency-plan-form">
                                        <a class="kt-menu__link">
                                            <i class="kt-menu__link-bullet kt-menu__link-bullet--line"><span></span></i>
                                            <span class="kt-menu__link-text">Emergency Plan Form</span>
                                        </a>
                                    </router-link>
                                    <router-link aria-haspopup="true" class="kt-menu__item" tag="li"
                                                 to="/eplan/what-to-do-after">
                                        <a class="kt-menu__link">
                                            <i class="kt-menu__link-bullet kt-menu__link-bullet--line"><span></span></i>
                                            <span class="kt-menu__link-text">What To Do After</span>
                                        </a>
                                    </router-link>
                                </ul>
                            </div>
                        </li>
                        <router-link aria-haspopup="true" class="kt-menu__item" exact
                                     exact-active-class="kt-menu__item--active" tag="li" to="/events">
                            <a class="kt-menu__link ">
                                <i class="kt-menu__link-icon flaticon-event-calendar-symbol"></i>
                                <span class="kt-menu__link-text">Events</span>
                            </a>
                        </router-link>
                        <router-link aria-haspopup="true" class="kt-menu__item" exact
                                     exact-active-class="kt-menu__item--active" tag="li" to="/perks">
                            <a class="kt-menu__link">
                                <i class="kt-menu__link-icon flaticon-placeholder-3"></i>
                                <span class="kt-menu__link-text">Perks</span>
                            </a>
                        </router-link>
                        <!--                        <router-link aria-haspopup="true" class="kt-menu__item" exact-->
                        <!--                                     exact-active-class="kt-menu__item&#45;&#45;active" tag="li" to="/education">-->
                        <!--                            <a class="kt-menu__link">-->
                        <!--                                <i class="kt-menu__link-icon flaticon2-open-text-book"></i>-->
                        <!--                                <span class="kt-menu__link-text">Legacy Education</span>-->
                        <!--                            </a>-->
                        <!--                        </router-link>-->
                        <router-link aria-haspopup="true" class="kt-menu__item" exact
                                     exact-active-class="kt-menu__item--active" tag="li" to="/education">
                            <a class="kt-menu__link">
                                <i class="kt-menu__link-icon flaticon2-open-text-book"></i>
                                <span class="kt-menu__link-text">Education</span>
                            </a>
                        </router-link>
                        <router-link aria-haspopup="true" class="kt-menu__item" exact
                                     exact-active-class="kt-menu__item--active"
                                     tag="li" to="/facility-portal" v-show="showFacilityPortal">
                            <a class="kt-menu__link">
                                <i class="kt-menu__link-icon flaticon-network"></i>
                                <span class="kt-menu__link-text">Facility Program</span>
                            </a>
                        </router-link>
                    </ul>
                </div>
            </div>
        </div>
        <div @click="close" class="kt-aside-overlay" v-show="toggleMobileAside"></div>
    </section>
</template>

<script>
    export default {
        name: "SideMenu",
        props: {
            toggleMobileAside: {
                type: Boolean,
                default: false
            },
            showFacilityPortal: {
                type: Boolean,
                default: false
            },
            is_secondary: Boolean
        },
        data() {
            return {
                eplan: false
            }
        },
        methods: {
            close: function () {
                this.$emit('closeMobileAside');
            }
        }
    }
</script>

<style scoped>

    @media (max-height: 660px) {
        .scroll_normal {
            overflow-y: auto;
            width: 160px;
        }

        .scrolled-submenu {
            background-color: inherit !important;
            box-shadow: unset !important;
            margin-left: unset !important;
            position: relative !important;
            width: unset !important;
            border-radius: unset !important;
            animation: kt-aside-menu-submenu-fade-in 0.3s ease 1, kt-aside-menu-submenu-move-down 0.3s ease-out 1 !important;
        }
    }

    @media (max-height: 810px) {
        .scroll_facility {
            overflow-y: auto;
            width: 160px;
        }

        .scrolled-submenu {
            background-color: inherit !important;
            box-shadow: unset !important;
            margin-left: unset !important;
            position: relative !important;
            width: unset !important;
            border-radius: unset !important;
            animation: kt-aside-menu-submenu-fade-in 0.3s ease 1, kt-aside-menu-submenu-move-down 0.3s ease-out 1 !important;
        }    }
</style>
