<template>
    <section id="billing_account">
        <div class="row">
            <div class="col-xl-12">
                <div class="kt-portlet kt-portlet--height-fluid">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">Make a Payment
                                <small>Pay any remaining balance.
                                </small>
                            </h3>
                        </div>
                    </div>
                    <div class="kt-portlet__body">
                        <div class="kt-section kt-section--first">
                            <div class="kt-section__body">
                                <p>To add a new card to your account, please use the "Add New Card" feature. If you add
                                    new card, please <b>"Refresh the Page"</b>.</p>
                                <p>If you have any issues processing the payment, please contact our member
                                    service team at (877) 448-6839.</p>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div style="background-color: #f7f8fa; padding: 20px">
                                            <h3>Your account balance: <span style="float: right!important;">${{ member.balance.toFixed(2) }}</span>
                                            </h3>
                                        </div>
                                        <div class="table-responsive">
                                            <table class="table table-striped"
                                                   v-on:beganAddingCard="onAddingCard"
                                                   v-on:cardAdded="onCardAdded"
                                            >
                                                <thead>
                                                <tr>
                                                    <th colspan="4">Credit Cards</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr :key="card.id" v-for="card in creditCards">
                                                    <td class="kt-font-brand kt-font-bold">
                                                        {{card.cardType}} Ending in: {{card.cardNumber.slice(-4)}}
                                                    </td>
                                                    <td class="kt-font-brand kt-font-bold">
                                                        Expiration: {{card.expirationMonth + '/' + card.expirationYear}}
                                                    </td>
                                                    <td>
                                                        <button :disabled="card.defaultPaymentMethod"
                                                                @click="setDefaultCard(card.id)"
                                                                class="btn btn-bold btn-label-brand btn-sm">
                                                            <span v-if="card.defaultPaymentMethod">Default</span>
                                                            <span v-if="!card.defaultPaymentMethod">Set As Default</span>
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <button :disabled="cardToBeUsed(card)"
                                                                @click="card_id = card.id"
                                                                class="btn btn-bold btn-label-brand btn-sm">
                                                            Use This Card
                                                        </button>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="form-group form-group-last row"
                                             style="margin-bottom: 2em!important;">
                                            <div class="col-lg-12">
                                                <label class="kt-checkbox">
                                                    <input name="checkbox"
                                                           type="checkbox" v-model="acceptTerms">
                                                    <span style="top:-4px"></span>
                                                </label>
                                                <span class="terms_label">
                                                    I agree to the <u @click="termsAndConditionsModal()">Terms and Conditions</u> and understand the cancellation
                                                    policy. By activating my membership today, I authorize U.S. LawShield
                                                    to automatically renew my membership, charged to the method of payment provided.
                                                </span>
                                            </div>
                                        </div>
                                        <alert :message="message" :show="showAlert" :type="alertType"
                                               v-on:close-alert="showAlert = false"/>
                                        <div class="kt-widget11__action kt-align-right">
                                            <button @click="addCard"
                                                    class="btn btn-label-brand btn-bold btn-sm btn-wide" type="button">
                                                Add A New Card
                                            </button>
                                        </div>
                                        <div class="kt-widget11__action kt-align-left">
                                            <button :disabled="!acceptTerms || member.balance === 0"
                                                    @click="submitPayment"
                                                    class="btn btn-label-brand btn-bold btn-wide btn-upper"
                                                    type="button">
                                                Process Payment
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
    import CCModal from "./CCModal";
    import {CreditCardRepo} from '@/repositories/CreditCardRepo';
    import TermsAndConditions from "../../partials/TermsAndConditions";

    export default {
        props: {
            member: Object,
        },
        data() {
            return {
                card_id: null,
                acceptTerms: false,
                creditCards: [],
                beganAddingCard: false,
                showAlert: false,
                alertType: null,
                message: ''
            }
        },
        mounted() {
            this.fetchCreditCards();
        },
        watch: {
            member: function () {
                this.fetchCreditCards();
            }
        },
        methods: {
            cardToBeUsed: function (card) {

                if (card.defaultPaymentMethod && !this.card_id) {
                    this.card_id = card.id;
                }

                return this.card_id === card.id;
            },
            onAddingCard: function () {
                this.beganAddingCard = true;
            },
            onCardAdded: function (creditCardInfo) {
                this.beganAddingCard = false;

                const creditCard = {
                    id: creditCardInfo.id,
                    cardHolderInfo: {
                        cardHolderName: creditCardInfo.cardHolderName,
                        addressLine1: null,
                        addressLine2: null,
                        city: null,
                        state: null,
                        zipcode: null
                    },
                    cardNumber: creditCardInfo.creditCardNumber,
                    cardType: creditCardInfo.creditCardType,
                    defaultPaymentMethod: creditCardInfo.defaultPaymentMethod,
                    expirationMonth: creditCardInfo.expirationMonth,
                    expirationYear: creditCardInfo.expirationYear
                };

                if (creditCard.defaultPaymentMethod) {
                    this.creditCards.find(function (card) {
                        return card.defaultPaymentMethod === true;
                    }).defaultPaymentMethod = false;
                }

                this.showAlert = true;
                this.message = "Credit card was successfully added.";
                this.creditCards.unshift(creditCard);
            },
            fetchCreditCards: function () {
                const ccRepo = new CreditCardRepo(this.axios, this.member.zuora_id);
                ccRepo.fetchInfo()
                    .then(creditCards => {
                        this.creditCards = creditCards;
                    })
                    .catch(error => {
                        console.warn(error);
                        this.error = error;
                        this.failure = true;
                        this.alertType = 'danger';
                        this.message = "Something went wrong. Please try again later.";
                    })
                    .finally(() => this.loading = false);
            },
            addCard: function () {
                let _this = this;
                this.$modal.show(
                    CCModal,
                    {
                        member: this.member
                    },
                    {
                        name: 'add-card',
                        adaptive: true,
                        height: 'auto',
                        width: '60%',
                        minWidth: '320',
                        scrollable: true,
                        beganAddingCard: false
                    },
                    {
                        'before-close': _this.beforeClose,
                        'beganAddingCard': function (event) {
                            _this.onAddingCard(event);
                        },
                        'cardAdded': function (creditCard) {
                            _this.onCardAdded(creditCard);
                        }
                    }
                );
            },
            beforeClose(event) {
                if (this.beganAddingCard === true) {
                    if (confirm('Do you really want to close this form?') === false) {
                        event.stop();
                    }
                }
            },
            setDefaultCard: function (id) {
                this.axios.put("/zuora/members/" + this.member.zuora_id + "/creditCards/" + id, {
                    defaultPaymentMethod: true
                })
                    .then(response => {
                        if (response.data.success) {
                            let defaultCard = this.creditCards.find(function (card) {
                                return card.defaultPaymentMethod === true;
                            });
                            let newDefaultCard = this.creditCards.find(function (card) {
                                return card.id === response.data.paymentMethodId;
                            });
                            defaultCard.defaultPaymentMethod = false;
                            newDefaultCard.defaultPaymentMethod = true;
                            this.showAlert = true;
                            this.alertType = 'success';
                            this.message = "Credit card was successfully set to default.";
                            this.fetchCreditCards();
                        } else {
                            console.warn(response);
                            this.showAlert = true;
                            this.alertType = 'danger';
                            this.message = "Something went wrong. Please try again.";
                        }
                    })
                    .catch(error => {
                        console.warn(error);
                        this.showAlert = true;
                        this.alertType = 'danger';
                        this.message = "Something went wrong. Please try again later.";
                    });
            },
            termsAndConditionsModal: function () {
                this.$modal.show(TermsAndConditions,
                    {}, {
                        name: 'terms-and-conditions-modal',
                        adaptive: true,
                        height: 'auto',
                        width: '45%',
                        scrollable: true,
                    }
                );
            },
            submitPayment: function () {
                this.axios.post("/zuora/members/" + this.member.zuora_id + "/payments", {
                    paymentMethodId: this.card_id
                })
                    .then(response => {
                        if (response.data.Success) {
                            this.showAlert = true;
                            this.alertType = 'success';
                            this.message = response.data.Message;
                            location.reload();
                        } else {
                            console.warn(response);
                            this.showAlert = true;
                            this.alertType = 'danger';
                            this.message = response.data.Message;
                        }
                    })
                    .catch(error => {
                        console.warn(error);
                        this.showAlert = true;
                        this.alertType = 'danger';
                        this.message = "Something went wrong. Please try again later.";
                    });
            }
        }
    }
</script>

<style scoped>
    .terms_label {

    }
</style>
